//Copyright Breast Cancer Index || 2021

.simple-form-group {
  margin-bottom: 1rem;
}
.simple-text-label {
  display: block;
  color: #375679;
}
.simple-text-input {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 37, 41);
}