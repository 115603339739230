//Copyright Breast Cancer Index || 2021

@import '../../styles/website.scss';

.secondaryPatientNavbar {
  background:rgba(230,230,230,0.95);
  position:fixed;
  top:56px !important;
  width:100%;
  z-index:200 !important;

  @include bp-sm {
    top:62px !important;
  }
  @include bp-md-up {
    top:66px !important;
  }
  @include bp-lg-up {
    top:77px !important;
  }

  .navbar {
    background:transparent;
    padding:0 !important;
    
    @include bp-sm {
      padding: 0rem 1rem !important;
    }
    @include bp-md-up {
      padding: 0rem 1rem !important;
    }

    .navbar-nav {
      display:block;
      flex-direction:inherit;
      margin-top:0;

      @include bp-ls {
        display:flex;
        flex-direction:initial !important;
      }
      @include bp-md-up {
        display:flex;
      }

      .nav-item {
        display:block;
        margin:20px;

        @include bp-ls {
          display:flex;
          margin:auto 20px;
        }
        @include bp-md-up {
          display:flex;
          margin:auto 20px;
        }

        .nav-link {
          color:#775659 !important;
          line-height:1.1em;
          padding:6px 0;
          text-align:center;
        }
      }
    }
  }
}
