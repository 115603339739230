//Copyright Breast Cancer Index || 2021

@import "../../styles/website.scss";

.banner-link {
  color: white;
}

.notice {
  background: $color1;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 80px;

  @include bp-sm-up {
    font-size: 14px;
    padding: 20px;
  }
  @include bp-md-up {
    height: 56px;
  }
}

.top-nav-link {
  color: white;
}

.navbar {
  background: white;
  flex-flow: column;

  .navbar-brand {
    img {
      width: 180px;
      max-height: 28px;

      @include bp-md-up {
        width: 234px;
        max-height: 37px;
      }
    }
  }

  .nav-link {
    color: $color1 !important;
    font-size: 0.9rem;
    padding: 12px 0;
    text-decoration: none;

    @include bp-lg-up {
      border-bottom: 2px solid transparent;
      margin-left: 12px;
      margin-right: 12px;
      padding: 0.5rem 0 !important;

      &:hover {
        border-bottom: 2px solid $color4;
      }
      &.nav-link-active {
        border-bottom: 2px solid $color4;
      }
    }
  }

  .dropdown.nav-item {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;

    @include bp-lg-up {
      top: 8px;
    }

    .nav-link {
      padding: 0.5rem 0 !important;
      margin-right: 25px !important;
      width: 82px;

      @include bp-lg-up {
        padding: 0 0 8px 0 !important;
      }
    }
  }

  .dropdown-toggle::after {
    margin-left: 0 !important;
    position: relative !important;
    top: 2px !important;
    left: 6px !important;
  }

  /* Mobile Dropdown */
  .dropdown-menu {
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0;

    @include bp-lg-up {
      background-color: rgba(255, 255, 255, 0.8);
      // border:1px solid $color1;
      border-radius: 30px;
      margin-top: 9px;
      padding: 10px 0;
    }

    .dropdown-item {
      background-color: transparent;
      border: 0px;
      color: $color1;
      font-size: 0.9em;
      font-weight: 300;
      padding: 14px 20px;
      text-align: left;
      text-decoration: none;
      white-space: nowrap;

      @include bp-lg-up {
        background-color: rgba(200, 200, 200, 0.1);
        text-align: center;
      }

      &:first-child {
        border: 0;
        border-radius: 0px 30px 0 0;
      }
      &:last-child {
        border: 0;
        border-radius: 0 0 0px 30px;
      }

      &:focus,
      &:hover {
        border: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }
  .navbar-toggler {
    background-color: $color1;
    border: 0;
    border-radius: 20px 0 20px 20px;
    height: 40px;
    overflow: hidden;
    width: 40px;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='5 -3 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    &.home-hamburger {
      background-color: white;
      border-radius: 0;
      height: 35px;
      width: 35px;
      padding: 0;
      display: none;

      .icon {
        position: relative;
        display: block;
        height: 2px;
        width: 33px;
        background-color: transparent;

        &:before,
        &:after {
          background-color: $hologic-blue;
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          right: 0;
        }

        &:before {
          transform: rotate(45deg);
          top: 0;
        }

        &:after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }

      &.collapsed {
        .icon {
          background-color: $hologic-blue;

          &:before,
          &:after {
            transform: rotate(0);
          }

          &:before {
            top: -10px;
          }

          &:after {
            bottom: -10px;
          }
        }
      }
    }
  }
  .navbar-nav {
    justify-content: flex-end;
    padding: 11px 0 9px;

    &.home-nav {
      padding: 0;
      @include bp-xl {
        padding: 0 190px 0 0;
      }

      a {
        text-decoration: none;
        padding: 5px 0;

        @include bp-sm-up {
          color: $hologic-blue;
          background-color: $gold;
          padding: 7px 35px;
          border-radius: 20px;

          &:hover {
            background-color: $hologic-blue;
            color: white;
          }
        }
      }
    }

    &.xl-center {
      @include bp-xl {
        justify-content: flex-start;
        padding-left: 65px;
      }
    }
  }

  .menu-large {
    position: static !important;

    .megamenu {
      border: 0;
      background: #e9e9e8;
      padding: 10px 20px;
      text-align: center;
      width: 100%;

      @include bp-sm {
        margin-left: 0;
        margin-right: 0;
      }

      .dropdown-item {
        &:hover {
          background: none;
        }
        a {
          font-size: 0.9rem;
          font-weight: 300;
          text-decoration: none;
        }
      }
    }
  }
}
