.buttons {
	.btn {
		width: fit-content;
		margin: 0 auto;
		display: flex !important;
		align-items: center;
		gap: 10px;
		padding: 0;
		color: #6f2877;

		&:hover {
			color: #2b2967;
		}
	}
}

.btn {
	&.primary {
		width: fit-content;
		margin: 0 auto;
		display: flex!important;
		align-items: center;
		gap: 10px;
	}

	svg {
		width: 25px;
		height: 25px;
		margin-bottom: 3px;
	}
}