hr {
    border-top-color: #FFB100!important;
}
header {
    p {
        font-size: 0.85em;
        @media (min-width: 1200px) {
            font-size: 1em;
        }
    }

    .q {
        font-weight: bold;
        &.desktop {
            font-size: 1.3em;
        }
    }

    .bio {
        text-align: center;
        font-weight: bold;
        color: #FFB100;
        line-height: 1em;

        &.mobile {
            border-bottom: solid 1px #FFB100;
        }
    }

}
main, header {
    overflow-y: hidden;
    
    img.clover {
        max-height: 100%;
    }
    @media (min-width: 768px) {
        .w-lg-75 {
            max-width: 75%;
        }
    }
    .video-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        
        .play-button {
            width: 80px;
            aspect-ratio: 1/1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            z-index: 999;
            opacity: 1;
            transition: opacity 1.5s ease;
            
            @media (max-width: 767px) {
                width: 50px;
            }
            
            svg {
                width: 100%;
                height: 100%;
                color: #6f2877;
                fill: #6f2877;
            }
        }
        video {
            border-radius: 8px;
            overflow: hidden;
            width: 100%;
            border: solid 1px #FFB100;
        }
        
        &.video-playing {
            .play-button {
                display: none;
                opacity: 0;
            }
        }
    }
    .bleed-left {
        p {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 999px 999px 0;
            padding: 4em 5em 4em 0;
            background-color: #F9E29F;
    
            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transform: translateX(-99.9%);
                width: 100%;
                height: 100%;
                background-color: #F9E29F;
            }

            @media (max-width: 767px) {
                padding: 1em 4em 1em 0;
            }
        }
    }
    @media (max-width: 767px) {
        h2 {
            font-size: 1.6em;
        }
        h3 {
            font-size: 1.4em;
        }
        h4 {
            font-size: 1.2em;
        }
        .q,
        .bio {
            font-size: 0.9em;
        }
    }
}
@media (max-width: 767px) {
    .back-to-top {
        margin-top: 0;
    }
}