@import '../../styles/website.scss';

/* ARTICLES */
.articles {
  padding:60px 15px;

  .left-col {
    border:none !important;

    @include bp-md-up {
      border-right: 1px solid $color4 !important;
      padding-right: 40px;
    }
  }
  .right-col {
    .image, button {
      display:none;
    }
  }
}