@import "../../styles/website.scss";

/* SECONDARY FOOTER */
.secondary-footer {
  border-radius: 200px 0 0 0;
  padding: 80px 0;
  position: relative;
  width: 100%;

  @include bp-sm {
    border-radius: 300px 0 0 0;
    padding: 100px 0 60px;
  }

  @include bp-md-up {
    border-radius: 300px 0 0 0;
    padding: 60px 0 60px;
  }

  .shape-container {
    margin: 0 auto;
    max-width: 580px;

    @include bp-sm {
      width: fit-content;
    }

    @include bp-md-up {
      margin: inherit;
      width: fit-content;
    }

    a.element {
      color: $color9;
      display: block;
      margin: 0 auto 20px;
      text-decoration: none;
      width: 170px;

      &.count1 {
        display: block !important;
        margin: 0 auto !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: $gold;
        cursor: pointer;
      }

      @include bp-sm {
        display: inline-block;
        margin: 0 7px;
        vertical-align: top;
        width: 140px;
      }

      @include bp-md {
        display: inline-block;
        margin: 0 8px;
        vertical-align: top;
        width: 135px;
      }
      @include bp-lg-up {
        display: inline-block;
        margin: 0 10px;
        vertical-align: top;
        width: 170px;
      }

      .shape {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 50px 6px;
        width: 100%;

        .icon {
          margin: 20px auto;
          width: 70px;

          &.clipboard {
            width: 49px;
          }

          img {
            max-height: 68px;
            width: 100%;
          }
        }
        .label {
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          font-size: 0.875em;
          line-height: 1.25em;
          padding: 10px 20px;

          @include bp-sm {
            height: 69px;
          }
          @include bp-md {
            height: 69px;
          }
        }
      }
    }
    .disclaimer {
      color: #fff;
      font-size: 12px;
      margin: 10px auto 0;
      text-align: center;
      width: 270px;

      @include bp-lg-up {
        width: 320px;
      }

      a {
        color: #fff;
      }
    }
  }
}
.hasHero {
  .photo-container {
    bottom: 0px;
    display: none !important;
    position: absolute;

    @include bp-md {
      display: block !important;
      right: calc(50% - 380px);
      width: 240px;
    }
    @include bp-lg {
      display: block !important;
      right: calc(50% - 496px);
      width: 330px;
    }
    @include bp-xl {
      display: block !important;
      right: calc(50% - 580px);
      width: 370px;
    }

    img {
      width: 100%;
    }
  }
}
