//Copyright Breast Cancer Index || 2021

@import "../../styles/website.scss";

footer {
  color: #fff;
  background-color: $hologic-blue;
  font-weight: 300;
  padding: 85px 0;

  .container {
    @include bp-sm-up {
      padding-left: 0;
      padding-right: 0;
    }
    @include bp-lg-up {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .highlight-links ul,
  .links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding: 20px;
      text-align: center;
      width: 100%;

      &:before {
        content: "";
      }

      @include bp-sm {
        display: inline-block;
        margin-right: 20px;
        padding: inherit;
        text-align: inherit;
        width: inherit;
      }
      @include bp-md-up {
        display: inline-block;
        margin-right: 20px;
        padding: inherit;
        text-align: inherit;
        width: inherit;
      }
    }
  }
  .highlight-links {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.25em;
    text-align: center;

    @include bp-md-up {
      border: 0;
      font-size: 0.875em;
      text-align: left;
    }

    li {
      margin-right: 0;

      &:first-child {
        margin-right: 30px;
      }

      a {
        color: $color4;
      }
    }
  }

  .links {
    font-size: 1em;
    font-weight: 400;
    line-height: 2.5em;
    text-align: center;

    @include bp-md-up {
      font-size: 0.875em;
      text-align: left;
    }
  }
  .logo {
    text-align: center;

    @include bp-md-up {
      text-align: left;
      vertical-align: top;
    }

    img {
      max-width: 250px;
    }
  }
  .address {
    display: block;
    font-size: 14px;
    text-align: center;

    @include bp-md-up {
      display: inline-block;
      font-size: 0.875em;
      text-align: left;
      vertical-align: top;
    }
  }
  .social {
    padding: 0 0 20px 0;
    text-align: center;

    @include bp-md-up {
      text-align: left;
    }

    li {
      margin: 0 10px;

      @include bp-md-up {
        margin: 0 15px 0 0;
      }
    }

    img {
      width: 34px;
    }
  }
  .copyright {
    font-size: 0.8em;
    text-align: center;

    @include bp-md-up {
      font-size: 0.75em;
      text-align: left;
      width: 80%;
    }

    p {
      margin-bottom: 30px;

      @include bp-md-up {
        margin-bottom: 10px;
      }
    }
  }
  .footer-nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 10px;
        padding: 0;
        text-align: center;

        @include bp-md-up {
          text-align: left;
        }

        &:before {
          display: none;
        }

        a {
          padding: 8px 0;
          color: #57cae6;
          font-size: 12px;
        }
      }
    }
  }
  .references {
    font-size: 10px;
    line-height: 1.5em;
    margin-top: 50px;

    @include bp-md-up {
      font-size: 0.625em;
      padding: 0 80px 0 55px;
      margin-top: 4px;
    }

    a {
      text-decoration: underline;
    }

    b {
      display: block;
    }
  }

  .a-hologic-company {
    font-size: 11px;
    text-align: center;
    font-weight: 600;

    @include bp-md-up {
      text-align: left;
    }
  }
}
