@import "../../styles/shared";

.hero {
  margin-top: 125px;
  background: url("/home-hero-mob-cropped.png") no-repeat right bottom,
    url("/Petal_background.svg") no-repeat calc(100% + 70px) 10px;
  background-size: contain, 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    width: 130%;
    height: 300%;
    left: 0;
    top: 0;
    background: radial-gradient(ellipse 62% 60% at 60% 65%, #d3314c, #d3314c 15%, #6f2877 63%, #2b2967 100%);
    transform: translateY(-50%);
    position: absolute;
    z-index: -1;
  }

  @include bp-sm-up {
    background: url("/home-hero-mob.png") no-repeat right bottom, url("/Petal_background.svg") no-repeat calc(100% + 50px) -30px;
    background-size: contain, 420px;
    min-height: 600px;
  }

  @include bp-md-up {
    margin-top: 105px;
  }

  @include bp-lg-up {
    background: url("/home-hero.png") no-repeat calc(50% + 280px) bottom,
      url("/Petal_background.svg") no-repeat calc(50% + 300px) -110px,
      radial-gradient(circle at calc(50% + 290px) 40%, #d3314c, #d3314c 15%, #6f2877 40%, #2b2967 80%);
    background-size: contain, 770px, auto;
    margin-top: 110px;
    min-height: 660px;

    &:before {
      display: none;
    }
  }

  .container {
    .column {
      margin: 20px -15px 70px;

      @include bp-sm-up {
        margin: 0 -30px;
      }

      @include bp-lg-up {
        margin: 0 -15px;
      }

      .hero-content {
        margin-bottom: 45px;

        @include bp-sm-up {
          margin-bottom: 0;
        }

        h1 {
          max-width: 320px;

          @include bp-sm-up {
            max-width: 85%;
            font-size: 1.6rem;
          }
          @include bp-lg-up {
            max-width: 85%;
            font-size: 2.5rem;
          }
          span {
            display: inline;
            @include bp-sm-up {
              display: block;
            }
          }
        }

        .sub-heading {
          font-weight: 300;
          font-size: 14px;
          width: 210px;

          strong {
            font-size: 16px;
          }

          br {
            display: none;
          }

          @include bp-sm-up {
            width: 80%;
          }

          @include bp-md-up {
            width: 100%;
            font-size: 20px;

            strong {
              font-size: 22px;
            }
            br {
              display: block;
            }
          }
        }
      }
    }
  }

  .home-hero-nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include bp-sm-up {
      margin-top: 85px;
      align-items: flex-start;
    }

    @include bp-lg-up {
      flex-direction: row;
      justify-content: space-between;
    }

    .btn {
      padding: 11px 5px;
      flex: 1;
      width: 275px;
      max-width: 275px;
      color: $hologic-blue;
      background: linear-gradient(#fff, #fff);

      &:not(:last-child) {
        margin-bottom: 17px;
      }

      &:hover {
        color: white;
        background: linear-gradient($hologic-blue, $hologic-blue);
      }

      &.go-to-providers {
        background: linear-gradient($gold, $gold);
      }

      @include bp-lg-up {
        &:not(:last-child) {
          margin-bottom: 0;
        }

        &.go-to-providers {
          display: none;
        }
      }
    }
  }
}
