//Copyright Breast Cancer Index || 2021

@import '../../styles/website.scss';

.filter {
  background:#e9e9e9 !important;
  padding: .5rem 0 !important;

  @include bp-lg-up {
    padding: .0.2rem 0 1rem !important;
  }

  .navbar-toggler {
    background:transparent;
    width:fit-content;

    &:before {
      color:$color1;
      content:'Filter By Topic';
      font-family:'roboto';
      font-size:16px;
      font-weight:500;
      position: relative;
      top: -2px;
    }
    &:after {
      color:$color4;
      content:' >';
      font-weight:500;
      position: relative;
      top: -2px;
    }
  
    .navbar-toggler-icon {
      display:none !important;
    }
  }

  .navbar-collapse {
    background-color:#f3f3f3;
    margin:8px 0 -10px;
    width:100%;

    @include bp-lg-up {
      background-color:initial;
      margin-top:0;
      max-width:100%;
      width:inherit;
    }

    .navbar-nav {
      align-items: initial;
      max-width:400px;
      padding:10px 0 40px;
      
      @include bp-lg-up {
        align-items:center;
        max-width:initial;
        padding:10px 0;
      }

      .endcap {
        @include bp-lg {
          width:180px;
        }
        @include bp-xl {
          width:270px;
        }
      }

      .nav-link {
        border-bottom:1px solid $color4;
        color:#775659 !important;
        font-family:'roboto';
        font-size:16px !important;
        font-weight:500;
        line-height:21.6px !important;
        margin:0 10px;
        padding:20px 10px;
        position:relative;
        text-align: left;
        text-decoration:none;

        &:after {
          border:1px solid $color4;
          border-radius:20px;
          content:'';
          display:block;
          height:40px;
          position:absolute;
          right:10px;
          top:10px;
          width:40px;
          background: white;
        }

        &.active {
          
          &:after {
            color:$color4;
            content:url(/icon-checkmark.svg);
            font-size:36px;
            line-height:38px;
            text-align:center;
          }
          @include bp-lg-up {
            border-bottom:2px solid $color4;
          }
        }

        @include bp-lg-up {
          border-bottom:2px solid transparent;
          margin: 0 10px 8px;
          padding: 4px 8px;
          text-align: center;

          &:after {
            display:none;
          }
        }

        span {
          display:none;
          font-family:'roboto';
          font-size:16px !important;
          font-weight:600;

          @include bp-lg-up {
            display:inline-block;
          }
        }

        &.label {
          color:$color1 !important;
          cursor:default;
          font-weight:600;
          padding-bottom:20px;
          pointer-events:none;
          text-align:center;
          white-space:nowrap;

          &:after {
            display:none;
          }

          @include bp-lg-up {
            padding:8px 12px;
            text-align:left;
          }
        }
        &.show-all {
          color:$color1 !important;
          font-weight:600;
          white-space:nowrap;

          .x-close {
            font-family:'Roboto';
            font-weight:100;
            font-size:22px !important;
            margin-left:10px;
          }
        }
      }
      .buttons {
		width: 100%;
		padding: 0 16px;
		justify-content: space-between;
        
		.btn-primary, 
		.btn-secondary {
          width:170px;
		  margin: 0;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  height:48px;
		  border-radius: 50px;

		  &:hover {
			background: white;
			border: 1px solid #6f2877;
			color: inherit;
		  }

          @include bp-ip5 {
            width:140px;
          }
        }
        .btn-primary {
          background-color:$color1;
          border:1px solid $color1;

          @include bp-ip5 {
            width:140px;
          }

          &::before,
		  &::after {
              display:none;
          }

		  &:hover {
			background: #6f2877;
			border: 1px solid #6f2877;
			color: inherit;
		  }
        }
      }
    }
  }
}
