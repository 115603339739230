/* BREAKPOINTS */
$xs-width: 321px;
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

@mixin bp-ip5 {
  @media (max-width: #{$xs-width - 1px}) {
    @content;
  }
}
@mixin bp-ls {
  @media (min-width: #{$xs-width}) and (max-width: #{$md-width - 1px}) and (orientation: landscape) {
    @content;
  }
}
@mixin bp-xs {
  @media (max-width: #{$sm-width - 1px}) {
    @content;
  }
}
@mixin bp-sm {
  @media (min-width: #{$sm-width}) and (max-width: #{$md-width - 1px}) {
    @content;
  }
}
@mixin bp-sm-up {
  @media (min-width: #{$sm-width}) {
    @content;
  }
}
@mixin bp-md {
  @media (min-width: #{$md-width}) and (max-width: #{$lg-width - 1px}) {
    @content;
  }
}
@mixin bp-md-up {
  @media (min-width: #{$md-width}) {
    @content;
  }
}
@mixin bp-lg {
  @media (min-width: #{$lg-width}) and (max-width: #{$xl-width - 1px}) {
    @content;
  }
}
@mixin bp-lg-up {
  @media (min-width: #{$lg-width}) {
    @content;
  }
}
@mixin bp-xl {
  @media (min-width: #{$xl-width}) {
    @content;
  }
}
@mixin bp-lg-down {
  @media (min-width: #{$xs-width}) and (max-width: #{$lg-width - 1px}) {
    @content;
  }
}

/* COLORS */
$color1: #6f2877;
$color2: #bca2c0;
$color3: #f0483e;
$color4: #feaa00;
$color5: #f9ad99;
$color6: #dc2b19;
$color7: #ffe2b1;
$color8: #fff1da;
$color9: #ffffff;
$color10: #8b8283;

// global colours
$bci-purple: #6f2877;
$purple-tint: #93328e;
$hologic-blue: #2b2967;
$cool-gray: #a7a8a9;

// patient colours
$patient-red: #ef3340;
$teal: #008aad;

%patient-gradient {
  background: linear-gradient($patient-red, $bci-purple 50%, $hologic-blue 90%);
}
%patient-gradient-h {
  background: linear-gradient(to right, $patient-red, $bci-purple 50%, $hologic-blue 90%);
}

//HCP colours
$gold: #ffb100;
$floaty-button: #ffc107;

%hcp-gradient {
  background: linear-gradient($hologic-blue, $purple-tint);
}
%hcp-gradient-h {
  background: linear-gradient(to right, $hologic-blue, $purple-tint);
}

//Added for transparency
$color11: rgba(255, 226, 177, 0.5);

$font1: "Roboto", sans-serif;
$font2: "Cantata One", serif;

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == "vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == "horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else if $orientation == "radial" {
    background: -webkit-radial-gradient(ellipse, $start-color, $end-color);
    background: radial-gradient(ellipse, $start-color, $end-color);
  } @else {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }
}
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}
.color-hologic-blue {
  color: $hologic-blue !important;
}
