@import "../../styles/website.scss";
.large-hero {
  height: 540px;
  margin-right: -70px;
}
p.hero {
  margin-top: 55px;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 190px;
  right: -11%;
  @media (max-width: 767px) {
    top: 394px;
    right: 3%;
  }
}
.ref-heading {
  padding-left: 3px;
  font-weight: bold;
  text-decoration: underline;
}
ol.ref {
  margin: 0;
  li {
    padding: 2px 0;
    
    a {
      word-break: break-all;
    }
  }
} 