@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
//Copyright Breast Cancer Index || 2021

.simple-form-group {
  margin-bottom: 1rem;
}
.simple-text-label {
  display: block;
  color: #375679;
}
.simple-text-input {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 400;
  color: rgb(33, 37, 41);
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

.banner-link {
  color: white;
}

.notice {
  background: #6f2877;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: 80px;
}
@media (min-width: 576px) {
  .notice {
    font-size: 14px;
    padding: 20px;
  }
}
@media (min-width: 768px) {
  .notice {
    height: 56px;
  }
}

.top-nav-link {
  color: white;
}

.navbar {
  background: white;
  flex-flow: column;
  /* Mobile Dropdown */
}
.navbar .navbar-brand img {
  width: 180px;
  max-height: 28px;
}
@media (min-width: 768px) {
  .navbar .navbar-brand img {
    width: 234px;
    max-height: 37px;
  }
}
.navbar .nav-link {
  color: #6f2877 !important;
  font-size: 0.9rem;
  padding: 12px 0;
  text-decoration: none;
}
@media (min-width: 992px) {
  .navbar .nav-link {
    border-bottom: 2px solid transparent;
    margin-left: 12px;
    margin-right: 12px;
    padding: 0.5rem 0 !important;
  }
  .navbar .nav-link:hover {
    border-bottom: 2px solid #feaa00;
  }
  .navbar .nav-link.nav-link-active {
    border-bottom: 2px solid #feaa00;
  }
}
.navbar .dropdown.nav-item {
  margin: 0;
  padding: 0;
  position: relative;
  top: 0px;
}
@media (min-width: 992px) {
  .navbar .dropdown.nav-item {
    top: 8px;
  }
}
.navbar .dropdown.nav-item .nav-link {
  padding: 0.5rem 0 !important;
  margin-right: 25px !important;
  width: 82px;
}
@media (min-width: 992px) {
  .navbar .dropdown.nav-item .nav-link {
    padding: 0 0 8px 0 !important;
  }
}
.navbar .dropdown-toggle::after {
  margin-left: 0 !important;
  position: relative !important;
  top: 2px !important;
  left: 6px !important;
}
.navbar .dropdown-menu {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}
@media (min-width: 992px) {
  .navbar .dropdown-menu {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 30px;
    margin-top: 9px;
    padding: 10px 0;
  }
}
.navbar .dropdown-menu .dropdown-item {
  background-color: transparent;
  border: 0px;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .navbar .dropdown-menu .dropdown-item {
    background-color: rgba(200, 200, 200, 0.1);
    text-align: center;
  }
}
.navbar .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 0px 30px 0 0;
}
.navbar .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 0px 30px;
}
.navbar .dropdown-menu .dropdown-item:focus, .navbar .dropdown-menu .dropdown-item:hover {
  border: 0;
  background-color: rgba(255, 255, 255, 0);
}
.navbar .navbar-toggler {
  background-color: #6f2877;
  border: 0;
  border-radius: 20px 0 20px 20px;
  height: 40px;
  overflow: hidden;
  width: 40px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='5 -3 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar .navbar-toggler.home-hamburger {
  background-color: white;
  border-radius: 0;
  height: 35px;
  width: 35px;
  padding: 0;
  display: none;
}
.navbar .navbar-toggler.home-hamburger .icon {
  position: relative;
  display: block;
  height: 2px;
  width: 33px;
  background-color: transparent;
}
.navbar .navbar-toggler.home-hamburger .icon:before, .navbar .navbar-toggler.home-hamburger .icon:after {
  background-color: #2b2967;
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  right: 0;
}
.navbar .navbar-toggler.home-hamburger .icon:before {
  transform: rotate(45deg);
  top: 0;
}
.navbar .navbar-toggler.home-hamburger .icon:after {
  transform: rotate(-45deg);
  bottom: 0;
}
.navbar .navbar-toggler.home-hamburger.collapsed .icon {
  background-color: #2b2967;
}
.navbar .navbar-toggler.home-hamburger.collapsed .icon:before, .navbar .navbar-toggler.home-hamburger.collapsed .icon:after {
  transform: rotate(0);
}
.navbar .navbar-toggler.home-hamburger.collapsed .icon:before {
  top: -10px;
}
.navbar .navbar-toggler.home-hamburger.collapsed .icon:after {
  bottom: -10px;
}
.navbar .navbar-nav {
  justify-content: flex-end;
  padding: 11px 0 9px;
}
.navbar .navbar-nav.home-nav {
  padding: 0;
}
@media (min-width: 1200px) {
  .navbar .navbar-nav.home-nav {
    padding: 0 190px 0 0;
  }
}
.navbar .navbar-nav.home-nav a {
  text-decoration: none;
  padding: 5px 0;
}
@media (min-width: 576px) {
  .navbar .navbar-nav.home-nav a {
    color: #2b2967;
    background-color: #ffb100;
    padding: 7px 35px;
    border-radius: 20px;
  }
  .navbar .navbar-nav.home-nav a:hover {
    background-color: #2b2967;
    color: white;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-nav.xl-center {
    justify-content: flex-start;
    padding-left: 65px;
  }
}
.navbar .menu-large {
  position: static !important;
}
.navbar .menu-large .megamenu {
  border: 0;
  background: #e9e9e8;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
@media (min-width: 576px) and (max-width: 767px) {
  .navbar .menu-large .megamenu {
    margin-left: 0;
    margin-right: 0;
  }
}
.navbar .menu-large .megamenu .dropdown-item:hover {
  background: none;
}
.navbar .menu-large .megamenu .dropdown-item a {
  font-size: 0.9rem;
  font-weight: 300;
  text-decoration: none;
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

/* SECONDARY FOOTER */
.secondary-footer {
  border-radius: 200px 0 0 0;
  padding: 80px 0;
  position: relative;
  width: 100%;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-footer {
    border-radius: 300px 0 0 0;
    padding: 100px 0 60px;
  }
}
@media (min-width: 768px) {
  .secondary-footer {
    border-radius: 300px 0 0 0;
    padding: 60px 0 60px;
  }
}
.secondary-footer .shape-container {
  margin: 0 auto;
  max-width: 580px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-footer .shape-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media (min-width: 768px) {
  .secondary-footer .shape-container {
    margin: inherit;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.secondary-footer .shape-container a.element {
  color: #ffffff;
  display: block;
  margin: 0 auto 20px;
  text-decoration: none;
  width: 170px;
}
.secondary-footer .shape-container a.element.count1 {
  display: block !important;
  margin: 0 auto !important;
}
.secondary-footer .shape-container a.element:last-child {
  margin-bottom: 0;
}
.secondary-footer .shape-container a.element:hover {
  color: #ffb100;
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-footer .shape-container a.element {
    display: inline-block;
    margin: 0 7px;
    vertical-align: top;
    width: 140px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-footer .shape-container a.element {
    display: inline-block;
    margin: 0 8px;
    vertical-align: top;
    width: 135px;
  }
}
@media (min-width: 992px) {
  .secondary-footer .shape-container a.element {
    display: inline-block;
    margin: 0 10px;
    vertical-align: top;
    width: 170px;
  }
}
.secondary-footer .shape-container a.element .shape {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px 6px;
  width: 100%;
}
.secondary-footer .shape-container a.element .shape .icon {
  margin: 20px auto;
  width: 70px;
}
.secondary-footer .shape-container a.element .shape .icon.clipboard {
  width: 49px;
}
.secondary-footer .shape-container a.element .shape .icon img {
  max-height: 68px;
  width: 100%;
}
.secondary-footer .shape-container a.element .shape .label {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 0.875em;
  line-height: 1.25em;
  padding: 10px 20px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-footer .shape-container a.element .shape .label {
    height: 69px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .secondary-footer .shape-container a.element .shape .label {
    height: 69px;
  }
}
.secondary-footer .shape-container .disclaimer {
  color: #fff;
  font-size: 12px;
  margin: 10px auto 0;
  text-align: center;
  width: 270px;
}
@media (min-width: 992px) {
  .secondary-footer .shape-container .disclaimer {
    width: 320px;
  }
}
.secondary-footer .shape-container .disclaimer a {
  color: #fff;
}

.hasHero .photo-container {
  bottom: 0px;
  display: none !important;
  position: absolute;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hasHero .photo-container {
    display: block !important;
    right: calc(50% - 380px);
    width: 240px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hasHero .photo-container {
    display: block !important;
    right: calc(50% - 496px);
    width: 330px;
  }
}
@media (min-width: 1200px) {
  .hasHero .photo-container {
    display: block !important;
    right: calc(50% - 580px);
    width: 370px;
  }
}
.hasHero .photo-container img {
  width: 100%;
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

footer {
  color: #fff;
  background-color: #2b2967;
  font-weight: 300;
  padding: 85px 0;
}
@media (min-width: 576px) {
  footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  footer .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer a:hover {
  color: #fff;
  text-decoration: underline;
}
footer .highlight-links ul,
footer .links ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
footer .highlight-links ul li,
footer .links ul li {
  display: block;
  padding: 20px;
  text-align: center;
  width: 100%;
}
footer .highlight-links ul li:before,
footer .links ul li:before {
  content: "";
}
@media (min-width: 576px) and (max-width: 767px) {
  footer .highlight-links ul li,
footer .links ul li {
    display: inline-block;
    margin-right: 20px;
    padding: inherit;
    text-align: inherit;
    width: inherit;
  }
}
@media (min-width: 768px) {
  footer .highlight-links ul li,
footer .links ul li {
    display: inline-block;
    margin-right: 20px;
    padding: inherit;
    text-align: inherit;
    width: inherit;
  }
}
footer .highlight-links {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.25em;
  text-align: center;
}
@media (min-width: 768px) {
  footer .highlight-links {
    border: 0;
    font-size: 0.875em;
    text-align: left;
  }
}
footer .highlight-links li {
  margin-right: 0;
}
footer .highlight-links li:first-child {
  margin-right: 30px;
}
footer .highlight-links li a {
  color: #feaa00;
}
footer .links {
  font-size: 1em;
  font-weight: 400;
  line-height: 2.5em;
  text-align: center;
}
@media (min-width: 768px) {
  footer .links {
    font-size: 0.875em;
    text-align: left;
  }
}
footer .logo {
  text-align: center;
}
@media (min-width: 768px) {
  footer .logo {
    text-align: left;
    vertical-align: top;
  }
}
footer .logo img {
  max-width: 250px;
}
footer .address {
  display: block;
  font-size: 14px;
  text-align: center;
}
@media (min-width: 768px) {
  footer .address {
    display: inline-block;
    font-size: 0.875em;
    text-align: left;
    vertical-align: top;
  }
}
footer .social {
  padding: 0 0 20px 0;
  text-align: center;
}
@media (min-width: 768px) {
  footer .social {
    text-align: left;
  }
}
footer .social li {
  margin: 0 10px;
}
@media (min-width: 768px) {
  footer .social li {
    margin: 0 15px 0 0;
  }
}
footer .social img {
  width: 34px;
}
footer .copyright {
  font-size: 0.8em;
  text-align: center;
}
@media (min-width: 768px) {
  footer .copyright {
    font-size: 0.75em;
    text-align: left;
    width: 80%;
  }
}
footer .copyright p {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  footer .copyright p {
    margin-bottom: 10px;
  }
}
footer .footer-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer .footer-nav ul li {
  margin: 0 0 10px;
  padding: 0;
  text-align: center;
}
@media (min-width: 768px) {
  footer .footer-nav ul li {
    text-align: left;
  }
}
footer .footer-nav ul li:before {
  display: none;
}
footer .footer-nav ul li a {
  padding: 8px 0;
  color: #57cae6;
  font-size: 12px;
}
footer .references {
  font-size: 10px;
  line-height: 1.5em;
  margin-top: 50px;
}
@media (min-width: 768px) {
  footer .references {
    font-size: 0.625em;
    padding: 0 80px 0 55px;
    margin-top: 4px;
  }
}
footer .references a {
  text-decoration: underline;
}
footer .references b {
  display: block;
}
footer .a-hologic-company {
  font-size: 11px;
  text-align: center;
  font-weight: 600;
}
@media (min-width: 768px) {
  footer .a-hologic-company {
    text-align: left;
  }
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

/* BACK TO TOP */
.back-to-top {
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 0.875em;
  margin: 40px;
  text-align: center;
  text-decoration: none;
}
.back-to-top i {
  color: #feaa00;
  display: block;
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

.secondaryPatientNavbar {
  background: rgba(230, 230, 230, 0.95);
  position: fixed;
  top: 56px !important;
  width: 100%;
  z-index: 200 !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondaryPatientNavbar {
    top: 62px !important;
  }
}
@media (min-width: 768px) {
  .secondaryPatientNavbar {
    top: 66px !important;
  }
}
@media (min-width: 992px) {
  .secondaryPatientNavbar {
    top: 77px !important;
  }
}
.secondaryPatientNavbar .navbar {
  background: transparent;
  padding: 0 !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondaryPatientNavbar .navbar {
    padding: 0rem 1rem !important;
  }
}
@media (min-width: 768px) {
  .secondaryPatientNavbar .navbar {
    padding: 0rem 1rem !important;
  }
}
.secondaryPatientNavbar .navbar .navbar-nav {
  display: block;
  flex-direction: inherit;
  margin-top: 0;
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .secondaryPatientNavbar .navbar .navbar-nav {
    display: flex;
    flex-direction: initial !important;
  }
}
@media (min-width: 768px) {
  .secondaryPatientNavbar .navbar .navbar-nav {
    display: flex;
  }
}
.secondaryPatientNavbar .navbar .navbar-nav .nav-item {
  display: block;
  margin: 20px;
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .secondaryPatientNavbar .navbar .navbar-nav .nav-item {
    display: flex;
    margin: auto 20px;
  }
}
@media (min-width: 768px) {
  .secondaryPatientNavbar .navbar .navbar-nav .nav-item {
    display: flex;
    margin: auto 20px;
  }
}
.secondaryPatientNavbar .navbar .navbar-nav .nav-item .nav-link {
  color: #775659 !important;
  line-height: 1.1em;
  padding: 6px 0;
  text-align: center;
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

.filter {
  background: #e9e9e9 !important;
  padding: 0.5rem 0 !important;
}
@media (min-width: 992px) {
  .filter {
    padding: 0 0.2rem 0 1rem !important;
  }
}
.filter .navbar-toggler {
  background: transparent;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.filter .navbar-toggler:before {
  color: #6f2877;
  content: "Filter By Topic";
  font-family: "roboto";
  font-size: 16px;
  font-weight: 500;
  position: relative;
  top: -2px;
}
.filter .navbar-toggler:after {
  color: #feaa00;
  content: " >";
  font-weight: 500;
  position: relative;
  top: -2px;
}
.filter .navbar-toggler .navbar-toggler-icon {
  display: none !important;
}
.filter .navbar-collapse {
  background-color: #f3f3f3;
  margin: 8px 0 -10px;
  width: 100%;
}
@media (min-width: 992px) {
  .filter .navbar-collapse {
    background-color: transparent;
    background-color: initial;
    margin-top: 0;
    max-width: 100%;
    width: inherit;
  }
}
.filter .navbar-collapse .navbar-nav {
  align-items: initial;
  max-width: 400px;
  padding: 10px 0 40px;
}
@media (min-width: 992px) {
  .filter .navbar-collapse .navbar-nav {
    align-items: center;
    max-width: none;
    max-width: initial;
    padding: 10px 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .filter .navbar-collapse .navbar-nav .endcap {
    width: 180px;
  }
}
@media (min-width: 1200px) {
  .filter .navbar-collapse .navbar-nav .endcap {
    width: 270px;
  }
}
.filter .navbar-collapse .navbar-nav .nav-link {
  border-bottom: 1px solid #feaa00;
  color: #775659 !important;
  font-family: "roboto";
  font-size: 16px !important;
  font-weight: 500;
  line-height: 21.6px !important;
  margin: 0 10px;
  padding: 20px 10px;
  position: relative;
  text-align: left;
  text-decoration: none;
}
.filter .navbar-collapse .navbar-nav .nav-link:after {
  border: 1px solid #feaa00;
  border-radius: 20px;
  content: "";
  display: block;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  background: white;
}
.filter .navbar-collapse .navbar-nav .nav-link.active:after {
  color: #feaa00;
  content: url(/icon-checkmark.svg);
  font-size: 36px;
  line-height: 38px;
  text-align: center;
}
@media (min-width: 992px) {
  .filter .navbar-collapse .navbar-nav .nav-link.active {
    border-bottom: 2px solid #feaa00;
  }
}
@media (min-width: 992px) {
  .filter .navbar-collapse .navbar-nav .nav-link {
    border-bottom: 2px solid transparent;
    margin: 0 10px 8px;
    padding: 4px 8px;
    text-align: center;
  }
  .filter .navbar-collapse .navbar-nav .nav-link:after {
    display: none;
  }
}
.filter .navbar-collapse .navbar-nav .nav-link span {
  display: none;
  font-family: "roboto";
  font-size: 16px !important;
  font-weight: 600;
}
@media (min-width: 992px) {
  .filter .navbar-collapse .navbar-nav .nav-link span {
    display: inline-block;
  }
}
.filter .navbar-collapse .navbar-nav .nav-link.label {
  color: #6f2877 !important;
  cursor: default;
  font-weight: 600;
  padding-bottom: 20px;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
}
.filter .navbar-collapse .navbar-nav .nav-link.label:after {
  display: none;
}
@media (min-width: 992px) {
  .filter .navbar-collapse .navbar-nav .nav-link.label {
    padding: 8px 12px;
    text-align: left;
  }
}
.filter .navbar-collapse .navbar-nav .nav-link.show-all {
  color: #6f2877 !important;
  font-weight: 600;
  white-space: nowrap;
}
.filter .navbar-collapse .navbar-nav .nav-link.show-all .x-close {
  font-family: "Roboto";
  font-weight: 100;
  font-size: 22px !important;
  margin-left: 10px;
}
.filter .navbar-collapse .navbar-nav .buttons {
  width: 100%;
  padding: 0 16px;
  justify-content: space-between;
}
.filter .navbar-collapse .navbar-nav .buttons .btn-primary,
.filter .navbar-collapse .navbar-nav .buttons .btn-secondary {
  width: 170px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 50px;
}
.filter .navbar-collapse .navbar-nav .buttons .btn-primary:hover,
.filter .navbar-collapse .navbar-nav .buttons .btn-secondary:hover {
  background: white;
  border: 1px solid #6f2877;
  color: inherit;
}
@media (max-width: 320px) {
  .filter .navbar-collapse .navbar-nav .buttons .btn-primary,
.filter .navbar-collapse .navbar-nav .buttons .btn-secondary {
    width: 140px;
  }
}
.filter .navbar-collapse .navbar-nav .buttons .btn-primary {
  background-color: #6f2877;
  border: 1px solid #6f2877;
}
@media (max-width: 320px) {
  .filter .navbar-collapse .navbar-nav .buttons .btn-primary {
    width: 140px;
  }
}
.filter .navbar-collapse .navbar-nav .buttons .btn-primary::before, .filter .navbar-collapse .navbar-nav .buttons .btn-primary::after {
  display: none;
}
.filter .navbar-collapse .navbar-nav .buttons .btn-primary:hover {
  background: #6f2877;
  border: 1px solid #6f2877;
  color: inherit;
}
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

.hero {
  margin-top: 125px;
  background: url("/home-hero-mob-cropped.png") no-repeat right bottom, url("/Petal_background.svg") no-repeat calc(100% + 70px) 10px;
  background-size: contain, 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  overflow: hidden;
}
.hero:before {
  content: "";
  width: 130%;
  height: 300%;
  left: 0;
  top: 0;
  background: radial-gradient(ellipse 62% 60% at 60% 65%, #d3314c, #d3314c 15%, #6f2877 63%, #2b2967 100%);
  transform: translateY(-50%);
  position: absolute;
  z-index: -1;
}
@media (min-width: 576px) {
  .hero {
    background: url("/home-hero-mob.png") no-repeat right bottom, url("/Petal_background.svg") no-repeat calc(100% + 50px) -30px;
    background-size: contain, 420px;
    min-height: 600px;
  }
}
@media (min-width: 768px) {
  .hero {
    margin-top: 105px;
  }
}
@media (min-width: 992px) {
  .hero {
    background: url("/home-hero.png") no-repeat calc(50% + 280px) bottom, url("/Petal_background.svg") no-repeat calc(50% + 300px) -110px, radial-gradient(circle at calc(50% + 290px) 40%, #d3314c, #d3314c 15%, #6f2877 40%, #2b2967 80%);
    background-size: contain, 770px, auto;
    margin-top: 110px;
    min-height: 660px;
  }
  .hero:before {
    display: none;
  }
}
.hero .container .column {
  margin: 20px -15px 70px;
}
@media (min-width: 576px) {
  .hero .container .column {
    margin: 0 -30px;
  }
}
@media (min-width: 992px) {
  .hero .container .column {
    margin: 0 -15px;
  }
}
.hero .container .column .hero-content {
  margin-bottom: 45px;
}
@media (min-width: 576px) {
  .hero .container .column .hero-content {
    margin-bottom: 0;
  }
}
.hero .container .column .hero-content h1 {
  max-width: 320px;
}
@media (min-width: 576px) {
  .hero .container .column .hero-content h1 {
    max-width: 85%;
    font-size: 1.6rem;
  }
}
@media (min-width: 992px) {
  .hero .container .column .hero-content h1 {
    max-width: 85%;
    font-size: 2.5rem;
  }
}
.hero .container .column .hero-content h1 span {
  display: inline;
}
@media (min-width: 576px) {
  .hero .container .column .hero-content h1 span {
    display: block;
  }
}
.hero .container .column .hero-content .sub-heading {
  font-weight: 300;
  font-size: 14px;
  width: 210px;
}
.hero .container .column .hero-content .sub-heading strong {
  font-size: 16px;
}
.hero .container .column .hero-content .sub-heading br {
  display: none;
}
@media (min-width: 576px) {
  .hero .container .column .hero-content .sub-heading {
    width: 80%;
  }
}
@media (min-width: 768px) {
  .hero .container .column .hero-content .sub-heading {
    width: 100%;
    font-size: 20px;
  }
  .hero .container .column .hero-content .sub-heading strong {
    font-size: 22px;
  }
  .hero .container .column .hero-content .sub-heading br {
    display: block;
  }
}
.hero .home-hero-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 576px) {
  .hero .home-hero-nav {
    margin-top: 85px;
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .hero .home-hero-nav {
    flex-direction: row;
    justify-content: space-between;
  }
}
.hero .home-hero-nav .btn {
  padding: 11px 5px;
  flex: 1 1;
  width: 275px;
  max-width: 275px;
  color: #2b2967;
  background: linear-gradient(#fff, #fff);
}
.hero .home-hero-nav .btn:not(:last-child) {
  margin-bottom: 17px;
}
.hero .home-hero-nav .btn:hover {
  color: white;
  background: linear-gradient(#2b2967, #2b2967);
}
.hero .home-hero-nav .btn.go-to-providers {
  background: linear-gradient(#ffb100, #ffb100);
}
@media (min-width: 992px) {
  .hero .home-hero-nav .btn:not(:last-child) {
    margin-bottom: 0;
  }
  .hero .home-hero-nav .btn.go-to-providers {
    display: none;
  }
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

/* ARTICLES */
.articles {
  padding: 60px 15px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .right-col .image, .articles .right-col button {
  display: none;
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}

.large-hero {
  height: 540px;
  margin-right: -70px;
}

p.hero {
  margin-top: 55px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  width: 160px;
  height: 160px;
  top: 190px;
  right: -11%;
}
@media (max-width: 767px) {
  .absolute {
    top: 394px;
    right: 3%;
  }
}

.ref-heading {
  padding-left: 3px;
  font-weight: bold;
  text-decoration: underline;
}

ol.ref {
  margin: 0;
}
ol.ref li {
  padding: 2px 0;
}
ol.ref li a {
  word-break: break-all;
}
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

section.side-effects .image {
  right: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 1000px) {
  section.side-effects .image {
    right: unset;
    left: 57%;
  }
}
section.side-effects .image p {
  max-width: 270px;
  text-align: right;
  color: #3e2b2f;
  text-shadow: 0 0 3px white;
}
section.side-effects .image .icon {
  align-self: flex-end;
  margin: 1rem 0;
  width: 270px;
}
section.side-effects .image .icon img {
  height: 3rem;
}
@media (max-width: 767px) {
  section.side-effects {
    margin-top: 0;
  }
  section.side-effects .image {
    margin: 0 auto 3rem;
    top: 0;
    position: unset;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  section.side-effects.full-round .content .bg {
    background: #5B1F61;
    left: unset;
    right: 35%;
    width: 100%;
    border-radius: 0 50% 50% 0;
  }
  section.side-effects.full-round .content .copy {
    width: 40%;
  }
}
.buttons .btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 0;
  color: #6f2877;
}
.buttons .btn:hover {
  color: #2b2967;
}

.btn.primary {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.btn svg {
  width: 25px;
  height: 25px;
  margin-bottom: 3px;
}
hr {
  border-top-color: #FFB100 !important;
}

header p {
  font-size: 0.85em;
}
@media (min-width: 1200px) {
  header p {
    font-size: 1em;
  }
}
header .q {
  font-weight: bold;
}
header .q.desktop {
  font-size: 1.3em;
}
header .bio {
  text-align: center;
  font-weight: bold;
  color: #FFB100;
  line-height: 1em;
}
header .bio.mobile {
  border-bottom: solid 1px #FFB100;
}

main, header {
  overflow-y: hidden;
}
main img.clover, header img.clover {
  max-height: 100%;
}
@media (min-width: 768px) {
  main .w-lg-75, header .w-lg-75 {
    max-width: 75%;
  }
}
main .video-wrap, header .video-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
main .video-wrap .play-button, header .video-wrap .play-button {
  width: 80px;
  aspect-ratio: 1/1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 999;
  opacity: 1;
  transition: opacity 1.5s ease;
}
@media (max-width: 767px) {
  main .video-wrap .play-button, header .video-wrap .play-button {
    width: 50px;
  }
}
main .video-wrap .play-button svg, header .video-wrap .play-button svg {
  width: 100%;
  height: 100%;
  color: #6f2877;
  fill: #6f2877;
}
main .video-wrap video, header .video-wrap video {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  border: solid 1px #FFB100;
}
main .video-wrap.video-playing .play-button, header .video-wrap.video-playing .play-button {
  display: none;
  opacity: 0;
}
main .bleed-left p, header .bleed-left p {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 999px 999px 0;
  padding: 4em 5em 4em 0;
  background-color: #F9E29F;
}
main .bleed-left p:before, header .bleed-left p:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(-99.9%);
  width: 100%;
  height: 100%;
  background-color: #F9E29F;
}
@media (max-width: 767px) {
  main .bleed-left p, header .bleed-left p {
    padding: 1em 4em 1em 0;
  }
}
@media (max-width: 767px) {
  main h2, header h2 {
    font-size: 1.6em;
  }
  main h3, header h3 {
    font-size: 1.4em;
  }
  main h4, header h4 {
    font-size: 1.2em;
  }
  main .q,
main .bio, header .q,
header .bio {
    font-size: 0.9em;
  }
}

@media (max-width: 767px) {
  .back-to-top {
    margin-top: 0;
  }
}
@charset "UTF-8";
/* BREAKPOINTS */
/* COLORS */
.nowrap {
  white-space: nowrap;
  display: inline !important;
  font-family: inherit;
}

.color-hologic-blue {
  color: #2b2967 !important;
}

/*smooth scroll*/
html {
  scroll-behavior: smooth;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  position: unset;
}

.bgc.c1 {
  background-color: #6f2877;
}
.bgc.c2 {
  background-color: #bca2c0;
}
.bgc.c3 {
  background-color: #f0483e;
}
.bgc.c4 {
  background-color: #feaa00;
}
.bgc.c5 {
  background-color: #f9ad99;
}
.bgc.c6 {
  background-color: #dc2b19;
}
.bgc.c10 {
  background-color: #8b8283;
}
.bgc.g1 {
  background: linear-gradient(to bottom, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g2 {
  background: linear-gradient(to left, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g3 {
  background: #6f2877;
  background: linear-gradient(to bottom, #6f2877, #f0483e);
  background: linear-gradient(to top, #ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.g4 {
  background: linear-gradient(to right, rgba(0, 138, 173, 0.2509803922), rgba(0, 0, 0, 0));
}
.bgc.g5 {
  background: #f0483e;
  background: linear-gradient(to bottom, #f0483e, #feaa00);
}
.bgc.g6 {
  background: rgba(255, 226, 177, 0.5);
  background: radial-gradient(ellipse, rgba(255, 226, 177, 0.5), #ffffff);
}
.bgc.g7 {
  background: #f0483e;
  background: linear-gradient(to right, #f0483e, #feaa00);
}
.bgc.patient-gradient {
  background: linear-gradient(#ef3340, #6f2877 50%, #2b2967 90%);
}
.bgc.hcp-gradient {
  background: linear-gradient(#2b2967, #93328e);
}
.bgc.hcp-gradient.horizontal {
  background: linear-gradient(to right, #2b2967, #93328e);
}

.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .bg-pattern {
    background-size: 200px;
  }
}

.clr.c1 {
  color: #6f2877;
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;
}
ul li:before {
  content: "•";
  font-size: 1em;
  left: -12px;
  position: absolute;
}

.f-300 {
  font-weight: 300 !important;
}

/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cantata One", serif;
}
h1.font1,
h2.font1,
h3.font1,
h4.font1,
h5.font1,
h6.font1,
p.font1,
span.font1 {
  font-family: "Roboto", sans-serif !important;
}

@media (max-width: 320px) {
  h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 1.5rem;
  }
}
h1.page-title {
  color: #6f2877;
  line-height: 1.125em;
}
h1.page-title span {
  color: #9b9a9b;
  display: block;
  margin-top: 15px;
  line-height: 1.5em;
}

h2.page-title {
  color: #6f2877;
  line-height: 1.125em;
}

/* GLOBAL OVERRIDES */
.navbar .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
  background-size: 18px 18px;
  background-position: top -2px center;
  width: 18px;
  height: 18px;
}

a,
.btn-link {
  color: #6f2877;
  text-decoration: underline;
}
a:hover,
.btn-link:hover {
  color: #feaa00;
  text-decoration: none;
}

a:focus, a:active, a:active:focus,
button:focus,
button:active,
button:active:focus {
  box-shadow: none !important;
  outline: none !important;
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: "Roboto", sans-serif;
}
body #root {
  margin-bottom: -30px;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;
}
ul li,
ol li {
  padding: 8px 0;
}
ul.t2 li,
ol.t2 li {
  padding: 0;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

@media (min-width: 576px) {
  .w-100-sm {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion .card {
  background: transparent;
  border: 0;
  color: #000;
  text-decoration: none;
}
.accordion .card .card-header {
  align-items: center;
  background-color: #fff;
  border-radius: 500px !important;
  border: 1px solid #6f2877;
  display: flex;
  font-weight: 700;
  padding: 20px;
  margin: 7px 0;
  cursor: pointer;
}
.accordion .card .card-header .fa {
  color: #feaa00;
  display: flex;
  font-size: 20px;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}
.accordion .card .card-body {
  font-size: 0.9em;
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;
}
.floaty-button.hcp {
  color: #2b2967;
  background-color: #ffc107;
}
.floaty-button.patient {
  text-align: center;
  color: #fff;
  background-color: #008aad;
  padding: 15px 13px 21px;
}
.floaty-button .hover-none {
  display: block;
}
.floaty-button .hover-show {
  display: none;
}
.floaty-button:hover {
  background-color: #2b2967;
  color: #fff;
}
.floaty-button:hover .hover-none {
  display: none;
}
.floaty-button:hover .hover-show {
  display: block;
}
@media (min-width: 768px) {
  .floaty-button {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }
}
@media screen and (max-height: 768px) {
  .floaty-button {
    bottom: 10vh;
  }
}
.floaty-button img {
  bottom: 15px;
  position: absolute;
  right: 10px;
  width: 20px;
}

body[os=win].modal-open .floaty-button {
  right: 17px;
}
body[os=win].modal-open .cookieConsent {
  width: calc(100% - 17px) !important;
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: #feaa00 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
}
.btn:link, .btn:visited {
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
}
.btn.w-250 {
  width: 250px;
}
@media (max-width: 320px) {
  .btn.w-250 {
    width: 200px;
  }
}
.btn.no-anim:hover {
  transform: none;
  transform: initial;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btn.no-anim:hover::after {
  transform: none;
  transform: initial;
  opacity: 0;
}
.btn.no-anim:hover:active {
  transform: translateY(0);
  box-shadow: none;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #f0483e;
  border-color: #6f2877;
}

.btn-primary {
  background: linear-gradient(#6f2877, #6f2877);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-primary {
    font-size: 1.125em;
  }
}
.btn-primary:hover {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp {
  background: linear-gradient(#2b2967, #2b2967);
}
.btn-primary.hcp:hover {
  background: linear-gradient(#fcb61a, #fcb61a);
  color: #2b2967 !important;
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid #6f2877;
  border-radius: 50px;
  color: #6f2877;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
@media (min-width: 768px) {
  .btn-secondary {
    font-size: 1.125em;
  }
}
.btn-secondary:hover {
  background: white;
  background: linear-gradient(#2b2967, #2b2967);
  border: 1px solid #2b2967;
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;
}
.btn-tertiary::after {
  background-color: transparent;
}
@media (min-width: 768px) {
  .btn-tertiary {
    font-size: 1.125em;
  }
}
.btn-tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
  color: #fff;
}
.btn-tertiary:active {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.btn-popmodal {
  background: #008aad;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-popmodal span {
  margin-top: 0.4em;
  font-size: 0.6em;
}
@media (max-width: 320px) {
  .btn-popmodal {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
}
.btn-popmodal:hover, .btn-popmodal.active {
  background: #6f2877;
}
@media (min-width: 576px) {
  .btn-popmodal:hover, .btn-popmodal.active {
    background: #6f2877;
  }
}
.btn-popmodal.count {
  background: #6f2877;
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal.count-mobile {
  background: #6f2877;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}
.btn-popmodal.count-mobile.active {
  background: #008aad;
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, #f0483e 0%, #feaa00 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;
}
@media (max-width: 320px) {
  .btn-popmodal-alt {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
@media (min-width: 1200px) {
  .btn-popmodal-alt {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
}
.btn-popmodal-alt:hover {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
}
.btn-popmodal-alt.count {
  background: linear-gradient(180deg, #f0483e 0%, #f0483e 100%);
  pointer-events: none;
  margin-bottom: 20px;
}
.btn-popmodal-alt.count-mobile {
  background: linear-gradient(180deg, #f0483e 0%, #feaa00 100%);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  left: 0p !important;
  pointer-events: none;
  position: relative !important;
  top: 20px !important;
}

.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form .form-group.arrow {
  position: relative;
}
form .form-group.arrow:after {
  border-color: #feaa00;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 12px;
  right: 20px;
  position: absolute;
  top: 15px;
  transform: rotate(135deg);
  vertical-align: top;
  width: 12px;
}
form .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
}
form select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
form input,
form select {
  border: 1px solid !important;
  border-color: #6f2877 !important;
  border-radius: 50px !important;
  height: 48px !important;
  padding: 10px 15px !important;
}
form .form-check-input {
  height: inherit !important;
}
form ::-webkit-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form :-ms-input-placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form ::placeholder {
  font-size: 0.9em;
  top: -1px;
  position: relative;
}
form .disclaimer {
  font-size: 0.7em;
}
form .disclaimer a {
  color: inherit;
}
form .hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.quote:before {
  background-image: url("/quote.svg");
  background-size: 25px 25px;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.36;
  width: 25px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .quote:before {
    background-size: 30px 30px;
    height: 30px;
    width: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .quote:before {
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 992px) {
  .quote:before {
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  .quote:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}
.quote:after {
  content: "”";
}
.quote.no-close:after {
  content: "";
}
.quote.big:before {
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
}

/* HEADERS */
header {
  /* HOME SPLASH PAGE */
  /* PAGE HEADERS */
}
header .intro-copy {
  margin-top: 80px;
}
@media (min-width: 768px) {
  header .intro-copy {
    margin-top: 0;
  }
}
header .video-poster {
  background: url("/home-video-poster.jpg") no-repeat;
  background-size: cover;
}
header.home-video {
  position: relative;
  background-color: black;
  height: 120vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 320px) {
  header.home-video {
    height: 180vh;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.home-video {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  header.home-video {
    height: 95vh;
  }
}
header.home-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
header.home-video .container {
  position: relative;
  z-index: 2;
  margin-top: 75px;
}
@media (min-width: 576px) {
  header.home-video .container {
    margin-top: 0px;
  }
}
header.home-video .container div div h1 {
  font-size: 1.4rem !important;
  line-height: normal;
}
@media (min-width: 992px) {
  header.home-video .container div div h1 {
    text-align: left;
  }
}
header.home-video .container div div h1 sup {
  font-size: 0.4em;
  position: relative;
  top: -12px;
}
@media (min-width: 576px) {
  header.home-video .container div div h1 {
    font-size: 2rem !important;
    top: -18px;
  }
}
header.home-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}
header.home-video .dropdown button {
  font-weight: 300;
  width: 300px;
}
header.home-video .dropdown button.dropdown-toggle {
  padding: 12px 20px;
}
header.home-video .dropdown button.dropdown-toggle:hover {
  transform: none;
  box-shadow: none;
}
header.home-video .dropdown button.dropdown-toggle:hover::after {
  transform: none;
  opacity: 0;
}
header.home-video .dropdown button.dropdown-toggle:active {
  transform: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
header.home-video .dropdown button.dropdown-toggle::after {
  display: none;
}
header.home-video .dropdown .dropdown-toggle::after {
  margin: 0 !important;
}
header.home-video .dropdown .dropdown-menu {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  border-radius: 30px;
  margin-top: 8px;
  padding: 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item {
  border: 1px solid #fff;
  color: #6f2877;
  font-size: 0.9em;
  font-weight: 300;
  padding: 14px 20px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:first-child {
  border: 0;
  border-radius: 30px 30px 0 0;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:last-child {
  border: 0;
  border-radius: 0 0 30px 30px;
}
header.home-video .dropdown .dropdown-menu .dropdown-item:focus, header.home-video .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}
header.home-video .dropdown .dropdown-menu.show {
  position: relative !important;
  transform: none !important;
}
@media (min-width: 768px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: absolute !important;
    transform: translate3d(0px, 47px, 0px) !important;
  }
}
@media (min-width: 992px) {
  header.home-video .dropdown .dropdown-menu.show {
    position: relative !important;
    top: -50px !important;
  }
}
header.page-header {
  margin-top: 55px;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header {
    margin-top: 63px;
  }
}
@media (min-width: 992px) {
  header.page-header {
    margin-top: 75px;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  header.page-header.patient-side {
    margin-top: 100px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
@media (min-width: 768px) {
  header.page-header.patient-side {
    margin-top: 100px;
  }
}
header.page-header .container-fluid {
  height: 360px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 320px) {
  header.page-header .container-fluid {
    height: 295px;
    width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid {
    height: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid {
    height: 460px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid {
    height: 490px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid {
    height: 510px;
    width: 90%;
  }
}
header.page-header .container-fluid .image {
  background: url("/img-header-contact.jpg");
  background-size: cover !important;
  background-position-x: center !important;
  height: 100% !important;
}
header.page-header .container-fluid .clover-container {
  height: 100%;
  position: relative;
  margin: 0 auto;
  width: 400px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container {
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container {
    width: 550px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container {
    width: 600px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container {
    width: 620px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container {
    width: 700px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy {
  height: auto;
  left: 30px;
  top: 40px;
  width: 300px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 10px;
    top: 25px;
    width: 290px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 40px;
    top: 60px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: 45px;
    top: 60px;
    width: 345px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -40px;
    top: 80px;
    width: 440px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .copy {
    left: -100px;
    top: 120px;
    width: 480px;
  }
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline {
  flex-direction: column;
}
header.page-header .container-fluid .clover-container.no-clover .copy.headline h1 {
  margin-top: 0;
}
header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
  bottom: 0;
  right: -100px;
  width: 270px;
  opacity: 0.15;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 0.15;
    right: -100px;
    width: 230px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -100px;
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -110px;
    width: 360px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 370px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container.no-clover .photo.ordertest {
    opacity: 1;
    right: -160px;
    width: 390px;
  }
}
header.page-header .container-fluid .clover-container .copy {
  align-items: center;
  display: flex;
  height: 180px;
  left: 9%;
  position: absolute;
  top: 35px;
  width: 170px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 128px;
    left: 33px;
    top: 20px;
    width: 140px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 240px;
    left: 60px;
    top: 55px;
    width: 220px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 250px;
    left: 55px;
    top: 65px;
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 260px;
    left: 60px;
    top: 60px;
    width: 280px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy {
    height: 300px;
    left: 60px;
    top: 80px;
    width: 320px;
  }
}
header.page-header .container-fluid .clover-container .copy.middle {
  height: 242px;
  top: 0px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 182px;
    top: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 332px;
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 362px;
    top: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 374px;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy.middle {
    height: 422px;
    top: 0px;
  }
}
header.page-header .container-fluid .clover-container .copy.headline {
  align-items: center;
  display: flex;
}
header.page-header .container-fluid .clover-container .copy h1 {
  color: #fff;
  font-size: 1em;
  line-height: 1.2em;
  margin-top: -25px;
  margin-bottom: 0;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 0.8em;
    margin-top: -25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.3em;
    margin-top: -30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.5em;
    margin-top: -40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.55em;
    margin-top: -50px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1 {
    font-size: 1.7em;
    margin-top: -60px;
  }
}
header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
  font-size: 1.5em;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.25em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 1.7em;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.25em;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .copy h1.faq, header.page-header .container-fluid .clover-container .copy h1.larger {
    font-size: 2.5em;
  }
}
header.page-header .container-fluid .clover-container .photo-clover {
  margin-top: 10px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo-clover {
    margin-top: 40px;
  }
}
header.page-header .container-fluid .clover-container .photo {
  position: absolute;
  right: -70px;
  bottom: 0px;
  width: 360px;
  z-index: 1;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -70px;
    width: 310px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -20px;
    width: 400px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 470px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 510px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo {
    right: -50px;
    width: 520px;
  }
}
header.page-header .container-fluid .clover-container .photo.large {
  right: -140px;
  width: 380px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -90px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 480px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 520px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.large {
    right: -175px;
    width: 540px;
  }
}
header.page-header .container-fluid .clover-container .photo.ordertest {
  right: -60px;
  width: 350px;
}
@media (max-width: 320px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: -80px;
    width: 300px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    right: 0px;
    width: 420px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -50px;
    width: 449px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 30px;
    right: -100px;
    width: 449px;
  }
}
@media (min-width: 1200px) {
  header.page-header .container-fluid .clover-container .photo.ordertest {
    bottom: 40px;
    right: -100px;
    width: 449px;
  }
}
header.page-header .not-round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
header.page-header .round {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  header.page-header .round {
    border-radius: 0 0 500px 0;
  }
}
header.page-header .round-left {
  border-radius: 0;
  color: #fff;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  header.page-header .round-left {
    border-radius: 0 0 0 500px;
  }
}
header.page-header.interactive .container-fluid {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
header.page-header.interactive .container-fluid .interactive-content {
  position: relative;
  z-index: 1;
}
header.page-header.interactive .container-fluid .interactive-content .highlight {
  color: #ffffff;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report {
  position: relative;
  margin: 0 auto;
  max-width: 466px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report .flip-report-btn {
  width: 100%;
  border: none;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
  left: -15px;
  top: 10%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
  left: 5%;
  top: 25%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
  left: 5%;
  top: 40%;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3a, header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
  right: 5%;
  top: 40%;
  border-radius: 50px 50px 50px 0 !important;
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b {
    right: 15px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.front .btn-popmodal.b3b span {
  font-size: 0.5em;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal {
  position: absolute;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
  left: -20px;
  top: 0px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 10px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b1 {
    left: -20px;
    top: 80px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
  left: 40px;
  top: 90px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 70px;
    top: 150px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b2 {
    left: 20px;
    top: 174px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
  left: 40px;
  top: 145px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 70px;
    top: 230px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b3 {
    left: 20px;
    top: 280px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
  left: -20px;
  top: 50px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -20px;
    top: 80px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b4 {
    left: -30px;
    top: 70px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
  left: 170px;
  top: 130px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 240px;
    top: 180px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b5 {
    left: 230px;
    top: 170px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
  left: -20px;
  top: 260px;
}
@media (min-width: 576px) and (max-width: 767px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -20px;
    top: 290px;
  }
}
@media (min-width: 768px) {
  header.page-header.interactive .container-fluid .interactive-content .report-container .report.back .btn-popmodal.b6 {
    left: -30px;
    top: 290px;
  }
}
header.page-header.interactive .container-fluid .interactive-content .report-container .report a {
  text-decoration: none;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls {
  color: #fff;
  line-height: 18px;
  margin: 0 auto;
  text-align: center;
  width: 150px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction {
  position: relative;
  font-size: 12px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before, header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  color: #feaa00;
  font-size: 50px;
  position: absolute;
  top: 8px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:before {
  content: "‹";
  left: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .instruction:after {
  content: "›";
  right: -20px;
}
header.page-header.interactive .container-fluid .interactive-content .report-container .controls .side {
  border-bottom: 2px solid #feaa00;
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0 auto;
  width: 65px;
}
header.page-header.float-hand .right-hand-mobile {
  margin-right: 0px;
}
header.page-header.float-hand .right-hand-tablet {
  margin-right: -40px;
  width: 350px;
}
header.page-header.float-hand .container-fluid {
  height: 100%;
}

@media (max-width: 767px) {
  .copy h1 br,
.copy h2 br,
.copy .page-title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .copy.headline h1 br,
.copy.headline h2 br,
.copy.headline .page-title br {
    display: block;
  }
}

/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;
}
.secondary-header .copy {
  color: #fff;
  font-size: 1.125em;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  margin: 20px auto;
  padding: 40px 20px 80px;
  max-width: 700px;
  width: 80%;
}
@media (min-width: 768px) {
  .secondary-header .copy {
    margin: 80px auto;
    padding: 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .secondary-header .copy br {
    display: none;
  }
}
.secondary-header .copy h6 {
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  margin-left: 30px;
}
@media (min-width: 768px) {
  .secondary-header .copy h6 {
    font-size: 22px;
    margin-left: 0;
  }
}
.secondary-header .copy img {
  margin: 20px 0;
  width: 40px;
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;
}
@media (min-width: 768px) {
  .full-round {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }
}
.full-round .image {
  position: absolute;
  right: 20%;
  top: -260px;
  width: 300px;
  z-index: 3;
}
@media (max-width: 320px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .image {
    top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .image {
    right: 10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .image {
    right: 15%;
  }
}
.full-round .image img {
  width: 100%;
}
@media (min-width: 768px) {
  .full-round .image img {
    width: auto;
    width: initial;
  }
}
@media (min-width: 768px) {
  .full-round .image {
    top: 25%;
    width: auto;
  }
}
.full-round .content {
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  padding: 3rem 1rem;
  position: relative;
  z-index: 2;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content {
    overflow: visible;
    padding: 4rem 3rem 6rem;
  }
}
@media (min-width: 992px) {
  .full-round .content {
    overflow: visible;
    padding: 8rem 3rem;
  }
}
.full-round .content .copy {
  position: relative;
  width: 100%;
  z-index: 2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .copy {
    padding-top: 0;
    width: 39%;
  }
}
.full-round .content .copy a {
  color: #fff;
}
.full-round .content .copy .hlines {
  border-top: 1px solid #feaa00;
  border-bottom: 1px solid #feaa00;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.full-round .content .copy .hlines ul {
  font-size: 0.9em;
}
.full-round .content .copy .hlines ul:last-child {
  margin-bottom: 0;
}
.full-round .content .bg {
  background: url("/bg-tear-left.svg") no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  left: -180%;
  position: absolute;
  top: 0;
  width: 460%;
  z-index: 0;
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round .content .bg {
    left: -60%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round .content .bg {
    left: -80%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round .content .bg {
    left: -40%;
    width: 129%;
  }
}
@media (min-width: 1200px) {
  .full-round .content .bg {
    left: -32%;
    width: 117%;
  }
}
.full-round.right {
  margin-top: 20px;
}
.full-round.right .image {
  top: -30px;
  left: 10%;
  width: 60%;
}
@media (max-width: 320px) {
  .full-round.right .image {
    top: 0px;
    left: 10%;
    width: 80%;
  }
}
@media (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
  .full-round.right .image {
    top: -70px !important;
    left: 30% !important;
    width: 350px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .image {
    left: 3%;
    top: -50px;
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .image {
    left: 3%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .image {
    left: 5%;
    top: 30%;
    width: 40%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .image {
    left: calc(30% - 260px);
    max-width: 400px;
    top: 30%;
    width: 30%;
  }
}
.full-round.right .image img {
  width: 100%;
}
.full-round.right .content {
  padding: 20em 3rem;
}
@media (min-width: 768px) {
  .full-round.right .content {
    overflow: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .copy {
    padding-top: 0;
    width: 60%;
  }
}
.full-round.right .content .bg {
  background: url("/bg-tear-right.svg") no-repeat;
  background-position: center;
  background-size: cover;
  left: -60%; /* -180 */
  width: 230%; /* 460 */
}
@media (min-width: 576px) and (max-width: 767px) {
  .full-round.right .content .bg {
    left: -50%;
    width: 210%;
  }
}
@media (min-width: 768px) {
  .full-round.right .content .bg {
    left: 0%;
    width: 190%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 140%;
  }
}
@media (min-width: 1200px) {
  .full-round.right .content .bg {
    left: 10%;
    width: 110%;
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round {
    margin-top: 240px;
  }
}
@media (min-width: 768px) {
  .half-round {
    margin-top: 0 !important;
    overflow: hidden;
  }
}
.half-round .image {
  position: absolute;
  right: 0;
  top: -180px;
  z-index: 3;
}
@media (max-width: 320px) {
  .half-round .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round .image {
    top: -300px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .image {
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .image {
    max-width: 650px;
    right: -14%;
    top: 20%;
  }
}
@media (min-width: 1200px) {
  .half-round .image {
    top: 20%;
    right: -10%;
    max-width: 700px;
  }
}
.half-round .image img {
  width: 100%;
  position: relative;
}
.half-round .content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.half-round .content .icon {
  margin: 40px auto 40px;
  width: 60px;
}
@media (min-width: 768px) {
  .half-round .content .icon {
    margin: 40px 0 20px 110px;
  }
}
.half-round .content .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round .content .copy {
    padding-top: 0;
    width: 55%;
  }
}
.half-round .content .copy hr {
  border-top-color: #feaa00;
}
.half-round .round-bg {
  background-color: #ef3340;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 768px) {
  .half-round .round-bg {
    border-radius: 0 500px 500px 0;
    width: 80%;
  }
}
.half-round.right .image {
  left: 0;
  top: -220px;
}
@media (max-width: 320px) {
  .half-round.right .image {
    top: -100px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .half-round.right .image {
    top: -260px;
    max-width: 600px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .image {
    left: 0px;
    max-width: 420px;
    top: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .image {
    left: -80px;
    max-width: 490px;
    top: 25%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .image {
    left: -80px;
    top: 25%;
    max-width: 560px;
  }
}
.half-round.right .image img {
  width: 100%;
}
.half-round.right .copy {
  padding-top: 40px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 45%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .half-round.right .copy {
    padding-top: 0;
    width: 60%;
  }
}
.half-round.right .round-bg {
  right: 0;
}
@media (min-width: 768px) {
  .half-round.right .round-bg {
    border-radius: 500px 0 0 500px;
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;
}
.qtr-round-article .content {
  margin: 0 auto;
  padding: 3rem 1rem;
  position: relative;
  z-index: 1;
}
.qtr-round-article .content .copy {
  padding-top: 40px;
  width: 100%;
}
.qtr-round-article .round-bg {
  border-radius: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 576px) {
  .qtr-round-article .round-bg {
    border-radius: 0 200px 0 0;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 255px);
    width: calc(50% + 255px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 345px);
    width: calc(50% + 345px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 465px);
    width: calc(50% + 465px);
  }
}
@media (min-width: 1200px) {
  .qtr-round-article .round-bg {
    margin-right: calc(50% - 555px);
    width: calc(50% + 555px);
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;
}
.timeline .container {
  padding-left: 40px;
}
@media (min-width: 576px) {
  .timeline .container {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .timeline .container {
    max-width: 100%;
  }
}
.timeline .container .left {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
@media (min-width: 768px) {
  .timeline .container .left {
    border: 0;
  }
}
.timeline .container .right {
  align-items: center;
  border-left: 1px dashed #008aad;
  display: flex;
}
.timeline .container h4,
.timeline .container h6 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
}
.timeline .container .odd {
  overflow: hidden;
  padding: 40px;
}
.timeline .container .odd.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd.hline {
    background: url("/timeline-hline-left.png") no-repeat;
    background-position: right -70px top 4px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .odd {
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .odd {
    padding: 0 100px 0 80px;
    text-align: right;
  }
}
.timeline .container .even {
  overflow: hidden;
  padding: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even {
    margin-bottom: 40px;
    padding: 40px 100px 40px 80px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even {
    padding: 0 80px 0 100px;
  }
}
.timeline .container .even.hline {
  background: url("/timeline-hline-right.png") no-repeat;
  background-position: left -130px top 44px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -90px top 44px;
  }
}
@media (min-width: 768px) {
  .timeline .container .even.hline {
    background: url("/timeline-hline-right.png") no-repeat;
    background-position: left -70px top 4px;
  }
}
.timeline .container .zero {
  margin-left: -31px;
  margin-top: -30px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .zero {
    height: 0;
    margin-left: -52px;
    margin-top: 0;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .zero {
    margin-left: -71px;
    width: 140px;
  }
}
.timeline .container .highlight {
  padding: 30px 16px;
}
@media (min-width: 768px) {
  .timeline .container .highlight {
    margin-top: 40px;
    padding: 30px 40px;
  }
}
.timeline .container .highlight .curly {
  display: inline-block;
  margin: 10px 10px 10px -10px;
  vertical-align: top;
  width: 30px;
}
@media (min-width: 768px) {
  .timeline .container .highlight .curly {
    margin: 10px;
    width: 40px;
  }
}
.timeline .container .highlight .copy {
  display: inline-block;
  width: calc(100% - 80px);
}
.timeline .container .photo1 {
  margin: 40px auto;
  padding: 35px 0 0;
  width: 80%;
}
@media (min-width: 768px) {
  .timeline .container .photo1 {
    margin: 0 auto;
    max-width: 380px;
  }
}
.timeline .container .photo2 {
  margin-left: -100px;
  max-width: 600px;
  width: 100%;
}
.timeline .container .year5 {
  margin-left: -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .year5 {
    height: 0;
    margin-left: -52px;
    position: relative;
    width: 100px;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .timeline .container .year5 {
    margin: 0 auto;
    width: 140px;
  }
}
.timeline .container .cta {
  margin-bottom: 40px;
  text-align: center;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta {
    text-align: center;
  }
}
.timeline .container .cta .year10 {
  display: block;
  margin: 0 40px 0 -31px;
  width: 60px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .year10 {
    display: inline-block;
    margin: 0 40px 0 -52px;
    width: 100px;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .year10 {
    display: block;
    margin: 0 auto 40px;
    width: 140px;
  }
}
.timeline .container .cta .button {
  display: block;
  margin-top: 40px;
}
@media (min-width: 576px) and (max-width: 767px) {
  .timeline .container .cta .button {
    display: inline-block;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .timeline .container .cta .button {
    display: block;
    margin-top: 0;
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;
}
.articles .left-col {
  border: none !important;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .articles .left-col {
    border-right: 1px solid #feaa00 !important;
    padding-right: 40px;
  }
}
.articles .left-col article {
  border-top: 1px solid #feaa00;
  margin-bottom: 40px;
  padding: 20px 0 0;
}
.articles .left-col article.featured {
  border: none;
  padding-top: 0;
}
.articles .left-col article.featured h2 {
  color: #3e2b2f;
}
.articles .left-col article img {
  margin: 30px 0;
  width: 100%;
}
.articles .left-col article h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 31px;
}
.articles .left-col article h2 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}
.articles .left-col article h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
@media (min-width: 576px) and (max-width: 767px) {
  .articles .right-col {
    padding-left: 20px;
  }
}
@media (min-width: 768px) {
  .articles .right-col {
    padding-left: 40px;
  }
}
.articles .right-col h4 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col article {
  border-bottom: 1px solid #feaa00;
  border-top: none;
  padding: 0 0 30px 0;
  margin-bottom: 30px;
}
.articles .right-col h1 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4em;
}
.articles .right-col h5 {
  color: #6f2877;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
}
.articles .right-col h6 {
  color: #707070;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 20px;
}
.articles .right-col p {
  font-weight: 400;
  margin: 0;
}
.articles .right-col .read-more {
  font-style: italic;
  text-decoration: none;
}
.articles .right-col .read-more:hover {
  text-decoration: underline;
}
.articles .right-col .read-more:after {
  content: " »";
}
.articles .right-col img {
  display: none;
}

.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}

.bracket-box {
  align-items: center;
  display: flex;
}
.bracket-box .curly {
  display: flex;
  min-width: 50px;
}
.bracket-box .curly.reverse {
  transform: scaleX(-1);
}

.x-bg {
  position: relative;
}
.x-bg:before {
  color: #f9ad99;
  content: "X";
  font-size: 300px;
  font-weight: 900;
  left: 25%;
  line-height: 200px;
  margin-left: -25%;
  opacity: 0.3;
  position: absolute;
  top: 0%;
  width: 100%;
  z-index: 0;
}
.x-bg h5 {
  position: relative;
  z-index: 1;
}

/* MODALS */
.modal {
  z-index: 9999;
}
.modal .modal-dialog {
  margin-left: auto;
}
@media (max-width: 575px) {
  .modal .modal-dialog {
    margin: 0;
    padding: 0 3%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .modal .modal-dialog {
    margin-top: 220px;
    margin-right: calc(50% - 400px);
    max-width: 290px;
  }
}
.modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  /*
      top: 250px;
      left: 80px;

      @include bp-md {
          top: 150px;
          left: 150px;
      }
      @include bp-lg{
          top: 160px;
          left: 230px;
      }
      @include bp-xl{
          top: 160px;
          left: 350px;
      }
      */
}
@media (max-width: 575px) {
  .modal .modal-content {
    font-size: 14px;
  }
}
@media (max-width: 320px) {
  .modal .modal-content {
    font-size: 12.5px;
  }
}
.modal .modal-content .modal-header {
  border: 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 30px;
}

.covid-modal {
  z-index: 9999;
}
.covid-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .covid-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.covid-modal .modal-content {
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.covid-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.covid-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
}
.covid-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.covid-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 50px 50px 35px;
}
.covid-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.purple-modal {
  z-index: 9999;
}
.purple-modal .modal-dialog {
  margin-left: auto;
}
@media (min-width: 576px) and (max-width: 767px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
  }
}
@media (min-width: 768px) {
  .purple-modal .modal-dialog {
    margin-top: 150px;
    margin-right: auto;
    max-width: 800px;
  }
}
.purple-modal .modal-content {
  background-color: rgba(111, 40, 120, 0.95);
  color: white;
  border: 0;
  border-radius: 80px 0;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.purple-modal .modal-content hr {
  background: #feaa00;
  width: 50%;
}
.purple-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  margin: 0 0 0 50px;
}
.purple-modal .modal-content .modal-header .modal-title {
  padding-top: 40px;
  margin: auto;
}
.purple-modal .modal-content .modal-header .close {
  color: #f0483e;
  font-size: 40px;
  opacity: 1;
  padding: 20px 30px 0 0;
  text-shadow: none;
  z-index: 9999;
}
.purple-modal .modal-content .modal-body {
  margin-top: 20px;
  margin: 20px 80px 50px 80px;
  text-align: center;
}
.purple-modal .modal-content .modal-body a {
  color: white;
}
.purple-modal .modal-content .modal-footer {
  border: 0;
  padding-bottom: 50px;
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.landing-cta-buttons > * {
  margin: 0 20px 20px 0;
}

.cookieConsent {
  align-items: center !important;
  padding: 20px !important;
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
}
@media (min-width: 576px) and (max-width: 767px) {
  .cookieConsent {
    padding: 20px 4vw !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cookieConsent {
    padding: 20px 12vw !important;
  }
}
@media (min-width: 992px) {
  .cookieConsent {
    padding: 20px 20vw !important;
  }
}
.cookieConsent .cookie-text a {
  color: #333;
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.hanging-quote {
  position: relative;
}
.hanging-quote:before {
  content: "“";
  position: absolute;
  left: -25px;
}

.share-btn {
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 24px;
}
.share-btn img {
  margin-right: 5px;
  margin-left: -10px;
}

.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}
.share-container:hover .share-tray {
  opacity: 1;
  visibility: visible;
}
.share-container .share-tray {
  display: inline-flex;
  position: absolute;
  visibility: hidden;
  bottom: -50px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  cursor: auto;
  grid-gap: 20px;
  gap: 20px;
}
.share-container .share-tray button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.share-container .share-tray button img {
  display: block;
  height: 100%;
  width: 100%;
}

.purple-background {
  color: white;
  background-color: #6f2877;
}

.lowercase {
  text-transform: lowercase;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}
.table-scroller {
  margin: 20px 0;
  width: 100%;
}
.table-scroller .scroll {
  color: #ccc;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 576px) {
  .table-scroller .scroll {
    display: none;
  }
}
.table-scroller .scroll-content {
  overflow: scroll;
  width: 100%;
}
.table-scroller table td {
  font-size: 14px;
}
@media (min-width: 576px) {
  .table-scroller table td {
    font-size: 16px;
  }
}

.contact-new-layout {
  display: flex;
  grid-gap: 100px;
  gap: 100px;
}
@media (max-width: 1024px) {
  .contact-new-layout {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }
}
.contact-new-layout > div {
  flex: 1 1;
}
.contact-new-layout > div h5 {
  font-size: clamp(20px, 2vw, 28px);
  margin-bottom: 18px;
  color: #000;
}
.contact-new-layout > div h6 {
  font-size: clamp(18px, 2vw, 22px);
  margin-bottom: 18px;
  color: #6f2877;
}
.contact-new-layout > div a {
  color: blue;
}
@media (min-width: 1024px) {
  .contact-new-layout > div:last-of-type p:first-of-type {
    padding-right: 20%;
  }
}
