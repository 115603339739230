@import '../../styles/website.scss';

/* BACK TO TOP */
.back-to-top {
  color:#000;
  cursor: pointer;
  display: block;
  font-size:0.875em;
  margin:40px;
  text-align:center;
  text-decoration:none;

  i {
      color:$color4;
      display:block;
  }
}