@import url("https://fonts.googleapis.com/css?family=Cantata+One|Roboto:300,400,700&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "./shared";

/*smooth scroll*/

html {
  scroll-behavior: smooth;
  // position: fixed;
  // overflow: hidden;
  // width: 100%;
  // height: 100%;

  // body {
  //   width: 100vw;
  //   height: 100vh;
  //   overflow-y: scroll;
  //   -webkit-overflow-scrolling: touch;
  // }
}

sup {
	font-size: 0.5em;
	vertical-align: super;
	position: unset;
}

.bgc {
  &.c1 {
    background-color: $color1;
  }
  &.c2 {
    background-color: $color2;
  }
  &.c3 {
    background-color: $color3;
  }
  &.c4 {
    background-color: $color4;
  }
  &.c5 {
    background-color: $color5;
  }
  &.c6 {
    background-color: $color6;
  }
  &.c10 {
    background-color: $color10;
  }

  &.g1 {
    // @include gradient(#{$color3}, #{$color1}, vertical);
    background: linear-gradient(to bottom, $patient-red, $bci-purple 50%, $hologic-blue 90%);
  }
  &.g2 {
    // @include gradient(#{$color1} -60%, #{$color3}, horizontal);
    background: linear-gradient(to left, $patient-red, $bci-purple 50%, $hologic-blue 90%);
  }
  &.g3 {
    @include gradient(#{$color1}, #{$color3}, vertical);
    background: linear-gradient(to top, $patient-red, $bci-purple 50%, $hologic-blue 90%);
  }
  &.g4 {
    background: linear-gradient(to right, #008aad40, #00000000);
  }
  &.g5 {
    @include gradient(#{$color3}, #{$color4}, vertical);
  }
  &.g6 {
    @include gradient(#{$color11}, #{$color9}, radial);
  }
  &.g7 {
    @include gradient(#{$color3}, #{$color4}, horizontal);
  }
  &.patient-gradient {
    background: linear-gradient($patient-red, $bci-purple 50%, $hologic-blue 90%);
  }
  &.hcp-gradient {
    background: linear-gradient($hologic-blue, $purple-tint);
    &.horizontal {
      background: linear-gradient(to right, $hologic-blue, $purple-tint);
    }
  }
}
.sup-sm {
  font-size: 50%;
}

.bg-pattern {
  background-image: url("/bg-pattern.png");
  background-size: 150px;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;

  @include bp-sm-up {
    background-size: 200px;
  }
}

.clr {
  &.c1 {
    color: $color1;
  }
}

.max-500 {
  max-width: 500px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.ref {
  font-size: 78%;
}

ul li {
  list-style-type: none;
  position: relative;

  &:before {
    content: "•";
    font-size: 1em;
    left: -12px;
    position: absolute;
  }
}
.f-300 {
  font-weight: 300 !important;
}
/* Jump link offset */
.jump:before {
  content: "";
  display: block;
  padding-top: 150px; /* header height + empty space */
  margin-top: -130px; /* header height to degrees padding’s space */
}

/* GLOBAL FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: $font2;
  &.font1 {
    font-family: $font1 !important;
  }
}

h1 {
  @include bp-ip5 {
    font-size: 1.5rem;
  }
  @include bp-xs {
    font-size: 1.5rem;
  }

  &.page-title {
    color: $color1;
    line-height: 1.125em;

    span {
      color: #9b9a9b;
      display: block;
      margin-top: 15px;
      line-height: 1.5em;
    }
  }
}

h2 {
  &.page-title {
    color: $color1;
    line-height: 1.125em;
  }
}

/* GLOBAL OVERRIDES */

.navbar {
  .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHJlY3QgeD0iMCIgeT0iMiIgY2xhc3M9InN0MCIgd2lkdGg9IjE3LjkiIGhlaWdodD0iMiIvPgo8cmVjdCB4PSIwIiB5PSI4IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+CjxyZWN0IHg9IjAiIHk9IjE0IiBjbGFzcz0ic3QwIiB3aWR0aD0iMTcuOSIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=") !important;
    background-size: 18px 18px;
    background-position: top -2px center;
    width: 18px;
    height: 18px;
  }
  .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTggMTg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNiwzLjQgMTQuNiwyIDksNy42IDMuNCwyIDIsMy40IDcuNiw5IDIsMTQuNiAzLjQsMTYgOSwxMC40IDE0LjYsMTYgMTYsMTQuNiAxMC40LDkgIi8+Cjwvc3ZnPgo=") !important;
    background-size: 18px 18px;
    background-position: top -2px center;
    width: 18px;
    height: 18px;
  }
  // img {
  //   height: 30px;

  //   @include bp-md-up {
  //     height: 37px;
  //   }
  // }
}

a,
.btn-link {
  color: $color1;
  text-decoration: underline;

  &:hover {
    color: $color4;
    text-decoration: none;
  }
}

a,
button {
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

b,
strong {
  font-weight: bold;
}

body {
  color: #3e2b2f;
  font-family: $font1;

	#root {
		margin-bottom: -30px;
	}
}

p {
  font-family: $font1;
  font-weight: 300;
}

ul,
ol {
  font-weight: 300;
  margin: 10px 0 20px 3px;
  padding: 0 0 0 15px;

  li {
    padding: 8px 0;
  }

  &.t2 {
    li {
      padding: 0;
    }
  }
}

.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.w-100-sm {
  @include bp-sm-up {
    width: 100% !important;
  }
}

.center-flex {
  justify-content: center;
}

/* ACCORDION */
.accordion {
  .card {
    background: transparent;
    border: 0;
    color: #000;
    text-decoration: none;

    .card-header {
      align-items: center;
      background-color: #fff;
      border-radius: 500px !important;
      border: 1px solid $color1;
      display: flex;
      font-weight: 700;
      padding: 20px;
      margin: 7px 0;
      cursor: pointer;

      .fa {
        color: #feaa00;
        display: flex;
        font-size: 20px;
        height: 18px;
        margin-right: 10px;
        width: 18px;
      }
    }

    .card-body {
      font-size: 0.9em;
    }
  }
}

/* FLOATY BUTTON */
.floaty-button {
  border-radius: 0 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  padding: 15px 13px 21px;
  position: fixed;
  bottom: 10vh;
  right: 0;
  text-decoration: none;
  z-index: 9998;
  width: 84px;

  &.hcp {
    color: $hologic-blue;
    background-color: $floaty-button;
  }

  &.patient {
    text-align: center;
    color: #fff;
    background-color: $teal;
    padding: 15px 13px 21px;
  }
  .hover-none {
    display: block;
  }
  .hover-show {
    display: none;
  }
  &:hover {
    background-color: $hologic-blue;
    color: #fff;
    .hover-none {
      display: none;
    }
    .hover-show {
      display: block;
    }
  }

  @include bp-md-up {
    width: 115px;
    font-size: 13px;
    bottom: 30vh;
    padding: 18px 15px 18px 20px;
  }

  @media screen and ( max-height: 768px ) {
	bottom: 10vh;
  }

  img {
    bottom: 15px;
    position: absolute;
    right: 10px;
    width: 20px;
  }
}

body[os="win"].modal-open {
  .floaty-button {
    right: 17px;
  }

  .cookieConsent {
    width: calc(100% - 17px) !important;
  }
}

/* BORDERS */
.border,
.border-bottom,
.border-right,
.border-top {
  border-color: $color4 !important;
  opacity: 1;
}

/* BUTTONS */
.btn {
  text-decoration: none;
  cursor: pointer;
  &:link,
  &:visited {
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: relative;
  }

  &.w-250 {
    width: 250px;

    @include bp-ip5 {
      width: 200px;
    }
  }
  &.no-anim {
    &:hover {
      transform: initial;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      &::after {
        transform: initial;
        opacity: 0;
      }
      &:active {
        transform: translateY(0);
        box-shadow: none;
      }
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: $color3;
  border-color: $color1;
}

.btn-primary {
  background: linear-gradient($bci-purple, $bci-purple);
  border: none;
  border-radius: 50px;
  color: #fff !important;
  font-size: 0.95em !important;
  padding: 12px 30px;

  @include bp-md-up {
    font-size: 1.125em;
  }

  &:hover {
    background: linear-gradient($hologic-blue, $hologic-blue);
  }

  &.hcp {
    background: linear-gradient($hologic-blue, $hologic-blue);

    &:hover {
      background: linear-gradient(#fcb61a, #fcb61a);
      color: $hologic-blue !important;
    }
  }
}

.btn-secondary {
  background: linear-gradient(white, white);
  border: 1px solid $bci-purple;
  border-radius: 50px;
  color: $bci-purple;
  font-size: 0.95em !important;
  padding: 12px 30px;

  @include bp-md-up {
    font-size: 1.125em;
  }

  &:hover {
    background: white;
    background: linear-gradient($hologic-blue, $hologic-blue);
    border: 1px solid $hologic-blue;
  }
}

.btn-tertiary {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 0.95em !important;
  padding: 12px 30px;

  &::after {
    background-color: transparent;
  }

  @include bp-md-up {
    font-size: 1.125em;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
    color: #fff;
  }

  &:active {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
  }
}

.btn-popmodal {
  background: $teal;
  border: 0 !important;
  border-radius: 50px 50px 0 50px !important;
  color: #fff !important;
  font-size: 1em !important;
  height: 30px;
  line-height: 1em;
  text-align: center;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  span {
	margin-top: 0.4em;
	font-size: 0.6em;
  }

  @include bp-ip5 {
    font-size: 0.9em !important;
    line-height: 1em;
    height: 25px;
    width: 25px;
  }
  @include bp-sm {
    font-size: 1.125em !important;
    height: 35px;
    width: 35px;
  }
  @include bp-md {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
  @include bp-lg {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }
  @include bp-xl {
    font-size: 1.5em !important;
    height: 40px;
    line-height: 1.125em !important;
    width: 40px;
  }

  &:hover,
  &.active {
    background: $bci-purple;
    @include bp-sm-up {
      background: $bci-purple;
    }
  }

  &.count {
    background: $bci-purple;
    pointer-events: none;
    margin-bottom: 20px;
  }
  &.count-mobile {
    background: $bci-purple;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
    left: 0p !important;
    pointer-events: none;
    position: relative !important;
    top: 20px !important;

    &.active {
      background: $teal;
    }
  }
}

.btn-popmodal-alt {
  background: linear-gradient(90deg, $color3 0%, $color4 100%);
  border: 0 !important;
  border-radius: 50px;
  color: #fff !important;
  font-size: 1.25em !important;
  height: 30px;
  line-height: 1.25em;
  text-align: center !important;
  width: 100px;
  max-width: 50% !important;

  @include bp-ip5 {
    font-size: 0.9em !important;
    line-height: 1.25em;
    height: 25px;
    width: 25px;
  }
  @include bp-sm {
    font-size: 1.25em !important;
    height: 35px;
    width: 35px;
  }
  @include bp-md {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
  @include bp-lg {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }
  @include bp-xl {
    font-size: 1.25em !important;
    height: 40px;
    line-height: 1.25em !important;
    width: 40px;
  }

  &:hover {
    background: linear-gradient(180deg, $color3 0%, $color3 100%);
  }

  &.count {
    background: linear-gradient(180deg, $color3 0%, $color3 100%);
    pointer-events: none;
    margin-bottom: 20px;
  }
  &.count-mobile {
    background: linear-gradient(180deg, $color3 0%, $color4 100%);
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
    left: 0p !important;
    pointer-events: none;
    position: relative !important;
    top: 20px !important;
  }
}
.btn-link {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: normal;
  font-weight: inherit;
  border: none;
  background: transparent;
  vertical-align: inherit;
  text-decoration: underline;
}

/* FORM */
form {
  .form-group {
    &.arrow {
      position: relative;

      &:after {
        border-color: $color4;
        border-style: solid;
        border-width: 0.25em 0.25em 0 0;
        content: "";
        display: inline-block;
        height: 12px;
        right: 20px;
        position: absolute;
        top: 15px;
        transform: rotate(135deg);
        vertical-align: top;
        width: 12px;
      }
    }
  }
  .form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(111, 40, 119, 0.25);
  }
  select {
    appearance: none;
  }
  input,
  select {
    border: 1px solid !important;
    border-color: $color1 !important;
    border-radius: 50px !important;
    height: 48px !important;
    padding: 10px 15px !important;
  }
  .form-check-input {
    height: inherit !important;
  }
  ::placeholder {
    font-size: 0.9em;
    top: -1px;
    position: relative;
  }
  .disclaimer {
    font-size: 0.7em;

    a {
      color: inherit;
    }
  }
  .hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

.quote {
  &:before {
    background-image: url("/quote.svg");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    content: "";
    display: block;
    height: 25px;
    margin-bottom: 2px;
    opacity: 0.36;
    width: 25px;

    @include bp-sm {
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
    }
    @include bp-md {
      background-size: 40px 40px;
      height: 40px;
      width: 40px;
    }
    @include bp-lg-up {
      background-size: 50px 50px;
      height: 50px;
      width: 50px;
    }
    @include bp-xl {
      background-size: 60px 60px;
      height: 60px;
      width: 60px;
    }
  }
  &:after {
    content: "”";
  }
  &.no-close:after {
    content: "";
  }
  &.big:before {
    background-size: 60px 60px;
    height: 60px;
    width: 60px;
  }
}

/* HEADERS */
header {
  .intro-copy {
    margin-top: 80px;

    @include bp-md-up {
      margin-top: 0;
    }
  }
  .video-poster {
    background: url("/home-video-poster.jpg") no-repeat;
    background-size: cover;
  }

  /* HOME SPLASH PAGE */
  &.home-video {
    position: relative;
    background-color: black;
    height: 120vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;

    @include bp-ip5 {
      height: 180vh;
    }
    @include bp-sm {
      height: 100vh;
    }
    @include bp-md-up {
      height: 95vh;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
    .container {
      position: relative;
      z-index: 2;
      margin-top: 75px; //increases from 50 to acommodate notice

      @include bp-sm-up {
        margin-top: 0px;
      }

      div {
        div {
          h1 {
            font-size: 1.4rem !important;
            line-height: normal;

            @include bp-lg-up {
              text-align: left;
            }

            sup {
              font-size: 0.4em;
              position: relative;
              top: -12px;
            }

            @include bp-sm-up {
              font-size: 2rem !important;
              top: -18px;
            }
          }
        }
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.5;
      z-index: 1;
    }
    .dropdown {
      button {
        font-weight: 300;
        width: 300px;

        &.dropdown-toggle {
          padding: 12px 20px;

          &:hover {
            transform: none;
            box-shadow: none;

            &::after {
              transform: none;
              opacity: 0;
            }
          }

          &:active {
            transform: none;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          }
          &::after {
            display: none;
          }
        }
      }
      .dropdown-toggle::after {
        margin: 0 !important;
      }
      .dropdown-menu {
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid #fff;
        border-radius: 30px;
        margin-top: 8px;
        padding: 0;

        .dropdown-item {
          border: 1px solid #fff;
          color: $color1;
          font-size: 0.9em;
          font-weight: 300;
          padding: 14px 20px;
          text-align: center;
          text-decoration: none;
          white-space: normal;

          &:first-child {
            border: 0;
            border-radius: 30px 30px 0 0;
          }
          &:last-child {
            border: 0;
            border-radius: 0 0 30px 30px;
          }

          &:focus,
          &:hover {
            background-color: rgba(255, 255, 255, 1);
          }
        }
        &.show {
          position: relative !important;
          transform: none !important;

          @include bp-md-up {
            position: absolute !important;
            transform: translate3d(0px, 47px, 0px) !important;
          }

          @include bp-lg-up {
            position: relative !important;
            top: -50px !important;
          }
        }
      }
    }
  }

  /* PAGE HEADERS */
  &.page-header {
    margin-top: 55px;
    position: relative;

    @include bp-md-up {
      margin-top: 63px;
    }

    @include bp-lg-up {
      margin-top: 75px;
    }

    &.patient-side {
      @include bp-ls {
        margin-top: 100px !important;
      }
      @include bp-sm {
        margin-top: 100px;
      }
      @include bp-md-up {
        margin-top: 100px;
      }
    }

    .container-fluid {
      height: 360px;
      margin: 0;
      padding: 0;
      position: relative;
      width: 100%;

      @include bp-ip5 {
        height: 295px;
        width: 100%;
      }
      @include bp-sm {
        height: 215px;
      }
      @include bp-md {
        height: 460px;
      }
      @include bp-lg {
        height: 490px;
      }
      @include bp-xl {
        height: 510px;
        width: calc(100% - 10%);
      }

      .image {
        background: url("/img-header-contact.jpg");
        background-size: cover !important;
        background-position-x: center !important;
        height: 100% !important;
      }

      .clover-container {
        height: 100%;
        position: relative;
        margin: 0 auto;
        width: 400px;
        z-index: 1;

        @include bp-ip5 {
          width: 300px;
        }
        @include bp-sm {
          width: 550px;
        }
        @include bp-md {
          width: 600px;
        }
        @include bp-lg {
          width: 620px;
        }
        @include bp-xl {
          width: 700px;
        }

        &.no-clover {
          .copy {
            height: auto;
            left: 30px;
            top: 40px;
            width: 300px;

            @include bp-ip5 {
              left: 10px;
              top: 25px;
              width: 290px;
            }
            @include bp-sm {
              left: 40px;
              top: 60px;
              width: 300px;
            }
            @include bp-md {
              left: 45px;
              top: 60px;
              width: 345px;
            }
            @include bp-lg {
              left: -40px;
              top: 80px;
              width: 440px;
            }
            @include bp-xl {
              left: -100px;
              top: 120px;
              width: 480px;
            }

            &.headline {
              flex-direction: column;

              h1 {
                margin-top: 0;
              }
            }
          }
          .photo {
            &.ordertest {
              bottom: 0;
              right: -100px;
              width: 270px;
              opacity: 0.15;

              @include bp-ip5 {
                opacity: 0.15;
                right: -100px;
                width: 230px;
              }
              @include bp-sm {
                opacity: 1;
                right: -100px;
                width: 300px;
              }
              @include bp-md {
                opacity: 1;
                right: -110px;
                width: 360px;
              }
              @include bp-lg {
                opacity: 1;
                right: -160px;
                width: 370px;
              }
              @include bp-xl {
                opacity: 1;
                right: -160px;
                width: 390px;
              }
            }
          }
        }

        .copy {
          align-items: center;
          display: flex;
          height: 180px;
          left: 9%;
          position: absolute;
          top: 35px;
          width: 170px;
          z-index: 1;

          @include bp-ip5 {
            height: 128px;
            left: 33px;
            top: 20px;
            width: 140px;
          }
          @include bp-sm {
            height: 240px;
            left: 60px;
            top: 55px;
            width: 220px;
          }
          @include bp-md {
            height: 250px;
            left: 55px;
            top: 65px;
            width: 265px;
          }
          @include bp-lg {
            height: 260px;
            left: 60px;
            top: 60px;
            width: 280px;
          }
          @include bp-xl {
            height: 300px;
            left: 60px;
            top: 80px;
            width: 320px;
          }
          &.middle {
            height: 242px;
            top: 0px;

            @include bp-ip5 {
              height: 182px;
              top: 0px;
            }
            @include bp-sm {
              height: 332px;
              top: 0px;
            }
            @include bp-md {
              height: 362px;
              top: 0px;
            }
            @include bp-lg {
              height: 374px;
              top: 0px;
            }
            @include bp-xl {
              height: 422px;
              top: 0px;
            }
          }
          &.headline {
            align-items: center;
            display: flex;
          }
          h1 {
            color: #fff;
            font-size: 1em;
            line-height: 1.2em;
            margin-top: -25px;
            margin-bottom: 0;
            // text-transform: capitalize;

            @include bp-ip5 {
              font-size: 0.8em;
              margin-top: -25px;
            }
            @include bp-sm {
              font-size: 1.3em;
              margin-top: -30px;
            }
            @include bp-md {
              font-size: 1.5em;
              margin-top: -40px;
            }
            @include bp-lg {
              font-size: 1.55em;
              margin-top: -50px;
            }
            @include bp-xl {
              font-size: 1.7em;
              margin-top: -60px;
            }

            &.faq,
            &.larger {
              font-size: 1.5em;

              @include bp-ip5 {
                font-size: 1.25em;
              }
              @include bp-sm {
                font-size: 1.7em;
              }
              @include bp-md {
                font-size: 2em;
              }
              @include bp-lg {
                font-size: 2.25em;
              }
              @include bp-xl {
                font-size: 2.5em;
              }
            }
          }
        }
        .photo-clover {
          margin-top: 10px;

          @include bp-sm {
            margin-top: 20px;
          }
          @include bp-md {
            margin-top: 35px;
          }
          @include bp-lg {
            margin-top: 30px;
          }
          @include bp-xl {
            margin-top: 40px;
          }
        }

        .photo {
          position: absolute;
          right: -70px;
          bottom: 0px;
          width: 360px;
          z-index: 1;

          @include bp-ip5 {
            right: -70px;
            width: 310px;
          }
          @include bp-sm {
            right: -20px;
            width: 400px;
          }
          @include bp-md {
            right: -50px;
            width: 470px;
          }
          @include bp-lg {
            right: -50px;
            width: 510px;
          }
          @include bp-xl {
            right: -50px;
            width: 520px;
          }

          &.large {
            right: -140px;
            width: 380px;

            @include bp-ip5 {
              right: -80px;
              width: 300px;
            }
            @include bp-sm {
              right: -90px;
              width: 420px;
            }
            @include bp-md {
              right: -175px;
              width: 480px;
            }
            @include bp-lg {
              right: -175px;
              width: 520px;
            }
            @include bp-xl {
              right: -175px;
              width: 540px;
            }
          }
          &.ordertest {
            right: -60px;
            width: 350px;

            @include bp-ip5 {
              right: -80px;
              width: 300px;
            }
            @include bp-sm {
              right: 0px;
              width: 420px;
            }
            @include bp-md {
              bottom: 30px;
              right: -50px;
              width: 449px;
            }
            @include bp-lg {
              bottom: 30px;
              right: -100px;
              width: 449px;
            }
            @include bp-xl {
              bottom: 40px;
              right: -100px;
              width: 449px;
            }
          }
        }
      }
    }
    .not-round {
      border-radius: 0;
      color: #fff;
      overflow: hidden;
      position: relative;
    }
    .round {
      border-radius: 0;
      color: #fff;
      overflow: hidden;
      position: relative;

      @include bp-md-up {
        border-radius: 0 0 500px 0;
      }
    }
    .round-left {
      border-radius: 0;
      color: #fff;
      overflow: hidden;
      position: relative;
      width: 100%;

      @include bp-md-up {
        border-radius: 0 0 0 500px;
      }
    }

    &.interactive {
      .container-fluid {
        height: fit-content;

        .interactive-content {
          position: relative;
          z-index: 1;

          .highlight {
            color: $color9;
            font-size: 12px;
          }

          .report-container {
            .report {
              position: relative;
              margin: 0 auto;
              max-width: 466px;

              .flip-report-btn {
                width: 100%;
                border: none;
                background-color: transparent;
                margin: 0 auto;
                cursor: pointer;
              }

              &.front {
                .btn-popmodal {
                  position: absolute;

                  &.b1 {
                    left: -15px;
                    top: 10%;

                    @include bp-md-up {
                      left: -20px;
                      top: 80px;
                    }
                  }
                  &.b2 {
                    left: 5%;
                    top: 25%;

                    @include bp-md-up {
                      left: 20px;
                      top: 174px;
                    }
                  }
                  &.b3a,
				  &.b3 {
                    left: 5%;
                    top: 40%;

                    @include bp-md-up {
                      left: 20px;
                      top: 280px;
                    }
                  }
				  &.b3b {
                    right: 5%;
                    top: 40%;
					border-radius: 50px 50px 50px 0 !important;

                    @include bp-md-up {
                      right: 15px;
                      top: 280px;
                    }
					span {
						font-size: 0.5em;
					}
                  }
                }
              }
              &.back {
                .btn-popmodal {
                  position: absolute;

				  &.b1 {
                    left: -20px;
                    top: 0px;

                    @include bp-sm {
                      left: -20px;
                      top: 10px;
                    }
                    @include bp-md-up {
                      left: -20px;
                      top: 80px;
                    }
                  }
                  &.b2 {
                    left: 40px;
                    top: 90px;

                    @include bp-sm {
                      left: 70px;
                      top: 150px;
                    }
                    @include bp-md-up {
                      left: 20px;
                      top: 174px;
                    }
                  }
                  &.b3 {
                    left: 40px;
                    top: 145px;

                    @include bp-sm {
                      left: 70px;
                      top: 230px;
                    }
                    @include bp-md-up {
                      left: 20px;
                      top: 280px;
                    }
                  }
				  
                  &.b4 {
                    left: -20px;
                    top: 50px;

                    @include bp-sm {
                      left: -20px;
                      top: 80px;
                    }
                    @include bp-md-up {
                      left: -30px;
                      top: 70px;
                    }
                  }
                  &.b5 {
                    left: 170px;
                    top: 130px;

                    @include bp-sm {
                      left: 240px;
                      top: 180px;
                    }
                    @include bp-md-up {
                      left: 230px;
                      top: 170px;
                    }
                  }
                  &.b6 {
                    left: -20px;
                    top: 260px;

                    @include bp-sm {
                      left: -20px;
                      top: 290px;
                    }
                    @include bp-md-up {
                      left: -30px;
                      top: 290px;
                    }
                  }
                }
              }
              a {
                text-decoration: none;
              }
            }
            .controls {
              color: #fff;
              line-height: 18px;
              margin: 0 auto;
              text-align: center;
              width: 150px;

              .instruction {
                position: relative;
                font-size: 12px;

				&:before,
				&:after {
					color: $color4;
					font-size: 50px;
					position: absolute;
					top: 8px;
				}

                &:before {
                  	content: "‹";
                  	left: -20px;
                }
                &:after {
					content: "›";
                  	right: -20px;
                }
              }
              .side {
                border-bottom: 2px solid $color4;
                display: block;
                font-size: 12px;
                font-weight: 600;
                margin: 0 auto;
                width: 65px;
              }
            }
          }
        }
      }
    }

    &.float-hand {
      .right-hand-mobile {
        margin-right: 0px;
      }
      .right-hand-tablet {
        margin-right: -40px;
        width: 350px;
      }
      .container-fluid {
        height: 100%;
      }
    }
  }
}
.copy {
  h1,
  h2,
  .page-title {
    @media (max-width: 767px) {
      br {
        display: none;
      }
    }
  }
  &.headline {
    h1,
    h2,
    .page-title {
      @media (max-width: 767px) {
        br {
          display: block;
        }
      }
    }
  }
}
/* SECONDARY HEADER */
.secondary-header {
  border-radius: 0 0 0 300px;
  position: relative;
  width: 100%;

  .copy {
    color: #fff;
    font-size: 1.125em;
    font-weight: 300;
    line-height: 1.25em;
    text-align: center;
    margin: 20px auto;
    padding: 40px 20px 80px;
    max-width: 700px;
    width: 80%;

    @include bp-md-up {
      margin: 80px auto;
      padding: 0;
    }

    br {
      @include bp-sm {
        display: none;
      }
    }

    h6 {
      font-family: $font1;
      margin-top: 30px;
      font-size: 18px;
      margin-left: 30px;

      @include bp-md-up {
        font-size: 22px;
        margin-left: 0;
      }
    }
    img {
      margin: 20px 0;
      width: 40px;
    }
  }
}

/* FULL ROUND AREA */
.full-round {
  color: #fff;
  margin-top: 240px;
  position: relative;
  width: 100%;

  @include bp-md-up {
    margin-bottom: 0 !important;
    margin-top: 0px;
    overflow: hidden;
  }

  .image {
    position: absolute;
    right: 20%;
    top: -260px;
    width: 300px;
    z-index: 3;

    @include bp-ip5 {
      top: -50px;
    }
    @include bp-sm {
      top: -50px;
    }
    @include bp-md {
      right: 10%;
    }
    @include bp-lg {
      right: 15%;
    }

    img {
      width: 100%;

      @include bp-md-up {
        width: initial;
      }
    }

    @include bp-md-up {
      top: 25%;
		width: auto;
    }
  }
  .content {
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;
    padding: 3rem 1rem;
    position: relative;
    z-index: 2;

    @include bp-sm {
      padding: 4rem 3rem;
    }

    @include bp-md {
      overflow: visible;
      padding: 4rem 3rem 6rem;
    }
    @include bp-lg-up {
      overflow: visible;
      padding: 8rem 3rem;
    }

    .copy {
      position: relative;
      width: 100%;
      z-index: 2;

      @include bp-md {
        padding-top: 0;
        width: 45%;
      }
      @include bp-lg {
        padding-top: 0;
        width: 50%;
      }
      @include bp-xl {
        padding-top: 0;
        width: 39%;
      }

      a {
        color: #fff;
      }

      .hlines {
        border-top: 1px solid $color4;
        border-bottom: 1px solid $color4;
        padding: 20px;
        width: fit-content;

        ul {
          font-size: 0.9em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .bg {
      background: url("/bg-tear-left.svg") no-repeat;
      background-position: center;
      background-size: cover;
      height: 100%;
      left: -180%;
      position: absolute;
      top: 0;
      width: 460%;
      z-index: 0;

      @include bp-sm {
        left: -60%;
        width: 210%;
      }
      @include bp-md-up {
        left: -80%;
        width: 190%;
      }
      @include bp-lg {
        left: -40%;
        width: 129%;
      }
      @include bp-xl {
        left: -32%;
        width: 117%;
      }
    }
  }
  &.right {
    margin-top: 20px;

    .image {
      top: -30px;
      left: 10%;
      width: 60%;

      @include bp-ip5 {
        top: 0px;
        left: 10%;
        width: 80%;
      }
      @include bp-ls {
        top: -70px !important;
        left: 30% !important;
        width: 350px !important;
      }
      @include bp-sm {
        left: 3%;
        top: -50px;
        width: 40%;
      }
      @include bp-md {
        left: 3%;
        top: 30%;
        width: 40%;
      }
      @include bp-lg {
        left: 5%;
        top: 30%;
        width: 40%;
      }
      @include bp-xl {
        left: calc(30% - 260px);
        max-width: 400px;
        top: 30%;
        width: 30%;
      }

      img {
        width: 100%;
      }
    }
    .content {
      padding: 20em 3rem;

      @include bp-md-up {
        overflow: visible;
      }

      .copy {
        @include bp-md {
          padding-top: 0;
          width: 45%;
        }
        @include bp-lg {
          padding-top: 0;
          width: 50%;
        }
        @include bp-xl {
          padding-top: 0;
          width: 60%;
        }
      }

      .bg {
        background: url("/bg-tear-right.svg") no-repeat;
        background-position: center;
        background-size: cover;
        left: -60%; /* -180 */
        width: 230%; /* 460 */

        @include bp-sm {
          left: -50%;
          width: 210%;
        }
        @include bp-md-up {
          left: 0%;
          width: 190%;
        }
        @include bp-lg {
          left: 10%;
          width: 140%;
        }
        @include bp-xl {
          left: 10%;
          width: 110%;
        }
      }
    }
  }
}

/* HALF ROUND AREA */
.half-round {
  color: #fff;
  margin-top: 160px;
  position: relative;

  @include bp-sm {
    margin-top: 240px;
  }

  @include bp-md-up {
    margin-top: 0 !important;
    overflow: hidden;
  }

  .image {
    position: absolute;
    right: 0;
    top: -180px;
    z-index: 3;

    @include bp-ip5 {
      top: -100px;
    }
    @include bp-sm {
      top: -300px;
      max-width: 600px;
    }
    @include bp-md {
      max-width: 420px;
      top: 25%;
    }
    @include bp-lg {
      max-width: 650px;
      right: -14%;
      top: 20%;
    }
    @include bp-xl {
      top: 20%;
      right: -10%;
      max-width: 700px;
    }

    img {
      width: 100%;
      position: relative;
    }
  }
  .content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 3rem 1rem;
    position: relative;
    z-index: 1;

    .icon {
      margin: 40px auto 40px;
      width: 60px;

      @include bp-md-up {
        margin: 40px 0 20px 110px;
      }
    }
    .copy {
      padding-top: 40px;
      width: 100%;

      @include bp-md {
        padding-top: 0;
        width: 45%;
      }
      @include bp-lg {
        padding-top: 0;
        width: 50%;
      }
      @include bp-xl {
        padding-top: 0;
        width: 55%;
      }

      hr {
        border-top-color: $color4;
      }
    }
  }
  .round-bg {
    background-color: $patient-red;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    @include bp-md-up {
      border-radius: 0 500px 500px 0;
      width: 80%;
    }
  }
  &.right {
    .image {
      left: 0;
      top: -220px;

      @include bp-ip5 {
        top: -100px;
      }
      @include bp-sm {
        top: -260px;
        max-width: 600px;
      }
      @include bp-md {
        left: 0px;
        max-width: 420px;
        top: 25%;
      }
      @include bp-lg {
        left: -80px;
        max-width: 490px;
        top: 25%;
      }
      @include bp-xl {
        left: -80px;
        top: 25%;
        max-width: 560px;
      }

      img {
        width: 100%;
      }
    }
    .copy {
      padding-top: 40px;
      width: 100%;

      @include bp-md {
        padding-top: 0;
        width: 45%;
      }
      @include bp-lg {
        padding-top: 0;
        width: 50%;
      }
      @include bp-xl {
        padding-top: 0;
        width: 60%;
      }
    }
    .round-bg {
      right: 0;

      @include bp-md-up {
        border-radius: 500px 0 0 500px;
      }
    }
  }
}

/* QUARTER ROUND ARTICLE AREA */
.qtr-round-article {
  color: #fff;
  position: relative;

  .content {
    margin: 0 auto;
    padding: 3rem 1rem;
    position: relative;
    z-index: 1;

    .copy {
      padding-top: 40px;
      width: 100%;
    }
  }
  .round-bg {
    border-radius: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    @include bp-sm-up {
      border-radius: 0 200px 0 0;
    }
    @include bp-sm {
      margin-right: calc(50% - 255px);
      width: calc(50% + 255px);
    }
    @include bp-md {
      margin-right: calc(50% - 345px);
      width: calc(50% + 345px);
    }
    @include bp-lg {
      margin-right: calc(50% - 465px);
      width: calc(50% + 465px);
    }
    @include bp-xl {
      margin-right: calc(50% - 555px);
      width: calc(50% + 555px);
    }
  }
}

/* TIMELINE */
.timeline-subtext {
  font-weight: 400;
  color: #3e2b2f;
}

.timeline {
  font-size: 0.9375em;

  .container {
    padding-left: 40px;

    @include bp-sm-up {
      padding: 0px;
    }
    @include bp-md {
      max-width: 100%;
    }

    .left {
      align-items: center;
      border-left: 1px dashed $teal;
      display: flex;

      @include bp-md-up {
        border: 0;
      }
    }
    .right {
      align-items: center;
      border-left: 1px dashed $teal;
      display: flex;
    }

    h4,
    h6 {
      color: $color1;
      font-family: $font1;
    }
    .odd {
      overflow: hidden;
      padding: 40px;

      &.hline {
        background: url("/timeline-hline-right.png") no-repeat;
        background-position: left -130px top 44px;

        @include bp-sm {
          background: url("/timeline-hline-right.png") no-repeat;
          background-position: left -90px top 44px;
        }
        @include bp-md-up {
          background: url("/timeline-hline-left.png") no-repeat;
          background-position: right -70px top 4px;
        }
      }

      @include bp-sm {
        padding: 40px 100px 40px 80px;
      }

      @include bp-md-up {
        padding: 0 100px 0 80px;
        text-align: right;
      }
    }
    .even {
      overflow: hidden;
      padding: 40px;

      @include bp-sm {
        margin-bottom: 40px;
        padding: 40px 100px 40px 80px;
      }

      @include bp-md-up {
        padding: 0 80px 0 100px;
      }

      &.hline {
        background: url("/timeline-hline-right.png") no-repeat;
        background-position: left -130px top 44px;

        @include bp-sm {
          background: url("/timeline-hline-right.png") no-repeat;
          background-position: left -90px top 44px;
        }
        @include bp-md-up {
          background: url("/timeline-hline-right.png") no-repeat;
          background-position: left -70px top 4px;
        }
      }
    }
    .zero {
      margin-left: -31px;
      margin-top: -30px;
      width: 60px;

      @include bp-sm {
        height: 0;
        margin-left: -52px;
        margin-top: 0;
        width: 100px;
      }

      @include bp-md-up {
        margin-left: -71px;
        width: 140px;
      }
    }
    .highlight {
      padding: 30px 16px;

      @include bp-md-up {
        margin-top: 40px;
        padding: 30px 40px;
      }
      .curly {
        display: inline-block;
        margin: 10px 10px 10px -10px;
        vertical-align: top;
        width: 30px;

        @include bp-md-up {
          margin: 10px;
          width: 40px;
        }
      }
      .copy {
        display: inline-block;
        width: calc(100% - 80px);
      }
    }
    .photo1 {
      margin: 40px auto;
      padding: 35px 0 0;
      width: 80%;

      @include bp-md-up {
        margin: 0 auto;
        max-width: 380px;
      }
    }
    .photo2 {
      margin-left: -100px;
      max-width: 600px;
      width: 100%;
    }
    .year5 {
      margin-left: -31px;
      width: 60px;

      @include bp-sm {
        height: 0;
        margin-left: -52px;
        position: relative;
        width: 100px;
        z-index: 1;
      }

      @include bp-md-up {
        margin: 0 auto;
        width: 140px;
      }
    }
    .cta {
      margin-bottom: 40px;
      text-align: center;

      @include bp-sm {
        text-align: left;
      }

      @include bp-md-up {
        text-align: center;
      }

      .year10 {
        display: block;
        margin: 0 40px 0 -31px;
        width: 60px;

        @include bp-sm {
          display: inline-block;
          margin: 0 40px 0 -52px;
          width: 100px;
        }

        @include bp-md-up {
          display: block;
          margin: 0 auto 40px;
          width: 140px;
        }
      }
      .button {
        display: block;
        margin-top: 40px;

        @include bp-sm {
          display: inline-block;
          margin-top: 0;
        }

        @include bp-md-up {
          display: block;
          margin-top: 0;
        }
      }
    }
  }
}

/* ARTICLES */
.articles {
  padding-bottom: 60px;
  padding-top: 60px;

  .left-col {
    border: none !important;

    @include bp-sm {
      border-right: 1px solid $color4 !important;
      padding-right: 20px;
    }
    @include bp-md-up {
      border-right: 1px solid $color4 !important;
      padding-right: 40px;
    }

    article {
      border-top: 1px solid $color4;
      margin-bottom: 40px;
      padding: 20px 0 0;

      &.featured {
        border: none;
        padding-top: 0;

		h2 {
			color: #3e2b2f;
		}
      }

      img {
        margin: 30px 0;
        width: 100%;
      }
      h1 {
        font-size: 25px;
        font-weight: 400;
        line-height: 31px;
      }
      h2 {
        color: $color1;
        font-family: $font1;
        font-size: 21px;
        font-weight: 500;
        line-height: 1.2;
      }
      h5 {
        color: $color1;
        font-family: $font1;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.4em;
      }
    }
  }
  .right-col {
    @include bp-sm {
      padding-left: 20px;
    }
    @include bp-md-up {
      padding-left: 40px;
    }

    h4 {
      color: $color1;
      font-family: $font1;
      font-size: 21px;
      font-weight: 600;
      line-height: 1.4em;
    }

    article {
      border-bottom: 1px solid $color4;
      border-top: none;
      padding: 0 0 30px 0;
      margin-bottom: 30px;
    }

    h1 {
      color: $color1;
      font-family: $font1;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4em;
    }
    h5 {
      color: $color1;
      font-family: $font1;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.4em;
    }
    h6 {
      color: #707070;
      font-family: $font1;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.4em;
      margin-bottom: 20px;
    }

    p {
      font-weight: 400;
      margin: 0;
    }
    .read-more {
      font-style: italic;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:after {
        content: " »";
      }
    }
    img {
      display: none;
    }
  }
}
.article-leaf {
  align-items: center;
  border-radius: 50% 50% 50% 0;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 120px;
  line-height: 18px;
  width: 120px;
}
.bracket-box {
  align-items: center;
  display: flex;

  .curly {
    display: flex;
    min-width: 50px;

    &.reverse {
      transform: scaleX(-1);
    }
  }
}
.x-bg {
  position: relative;

  &:before {
    color: $color5;
    content: "X";
    font-size: 300px;
    font-weight: 900;
    left: 25%;
    line-height: 200px;
    margin-left: -25%;
    opacity: 0.3;
    position: absolute;
    top: 0%;
    width: 100%;
    z-index: 0;
  }
  h5 {
    position: relative;
    z-index: 1;
  }
}

/* MODALS */
.modal {
  z-index: 9999;

  .modal-dialog {
    margin-left: auto;

    @include bp-xs {
      margin: 0;
      padding: 0 3%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include bp-sm {
      margin-top: 150px;
    }
    @include bp-md-up {
      margin-top: 220px;
      margin-right: calc(50% - 400px);
      max-width: 290px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 80px 0;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    @include bp-xs {
      font-size: 14px;
    }
    @include bp-ip5 {
      font-size: 12.5px;
    }
    /*
        top: 250px;
        left: 80px;

        @include bp-md {
            top: 150px;
            left: 150px;
        }
        @include bp-lg{
            top: 160px;
            left: 230px;
        }
        @include bp-xl{
            top: 160px;
            left: 350px;
        }
        */
    .modal-header {
      border: 0;
      padding: 0;

      .close {
        color: $color3;
        font-size: 40px;
        opacity: 1;
        padding: 20px 30px 0 0;
        text-shadow: none;
        z-index: 9999;
      }
    }
    .modal-footer {
      border: 0;
      padding-bottom: 30px;
    }
  }
}

.covid-modal {
  z-index: 9999;

  .modal-dialog {
    margin-left: auto;

    @include bp-sm {
      margin-top: 150px;
    }
    @include bp-md-up {
      margin-top: 150px;
      margin-right: auto;
      max-width: 800px;
    }
  }

  .modal-content {
    border: 0;
    border-radius: 80px 0;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    .modal-header {
      border: 0;
      padding: 0;
      margin: 0 0 0 50px;

      .modal-title {
        padding-top: 40px;
      }

      .close {
        color: $color3;
        font-size: 40px;
        opacity: 1;
        padding: 20px 30px 0 0;
        text-shadow: none;
        z-index: 9999;
      }
    }
    .modal-body {
      margin-top: 20px;
      margin: 20px 50px 50px 35px;
    }
    .modal-footer {
      border: 0;
      padding-bottom: 50px;
    }
  }
}

.purple-modal {
  z-index: 9999;

  .modal-dialog {
    margin-left: auto;

    @include bp-sm {
      margin-top: 150px;
    }
    @include bp-md-up {
      margin-top: 150px;
      margin-right: auto;
      max-width: 800px;
    }
  }

  .modal-content {
    background-color: rgba(111, 40, 120, 0.95);
    color: white;
    border: 0;
    border-radius: 80px 0;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    hr {
      background: $color4;
      width: 50%;
    }

    .modal-header {
      border: 0;
      padding: 0;
      margin: 0 0 0 50px;

      .modal-title {
        padding-top: 40px;
        margin: auto;
      }

      .close {
        color: $color3;
        font-size: 40px;
        opacity: 1;
        padding: 20px 30px 0 0;
        text-shadow: none;
        z-index: 9999;
      }
    }
    .modal-body {
      margin-top: 20px;
      margin: 20px 80px 50px 80px;
      text-align: center;

      a {
        color: white;
      }
    }
    .modal-footer {
      border: 0;
      padding-bottom: 50px;
    }
  }
}

.modal-backdrop {
  background-color: #fff;
  background-color: transparent;
}

.landing-cta-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin: 0 20px 20px 0;
  }
}
.cookieConsent {
  align-items: center !important;
  padding: 20px !important;

  @include bp-sm {
    padding: 20px 4vw !important;
  }
  @include bp-md {
    padding: 20px 12vw !important;
  }
  @include bp-lg-up {
    padding: 20px 20vw !important;
  }
  /*
    button:last-child {
        background:transparent !important;
        color:#cc0000 !important;
        content:'X' !important;
        font-size:50px !important;
        padding:0 !important;
    }
    */
  .cookie-text {
    a {
      color: #333;
      text-decoration: underline;
    }
  }
}
.no-wrap {
  white-space: nowrap;
}
.hanging-quote {
  position: relative;
  &:before {
    content: "“";
    position: absolute;
    left: -25px;
  }
}
.share-btn {
  display: inline-flex;
  width: fit-content;
  line-height: 24px;
  img {
    margin-right: 5px;
    margin-left: -10px;
  }
}
.share-container {
  display: inline-flex;
  position: relative;
  flex-direction: column;

  &:hover {
    .share-tray {
      opacity: 1;
      visibility: visible;
    }
  }
  .share-tray {
    display: inline-flex;
    position: absolute;
    visibility: hidden;
    bottom: -50px;
    opacity: 0;
    transition: all 0.15s ease-in-out;
    cursor: auto;
	gap: 20px;

    button {
      width: 20px;
      height: 20px;
      cursor: pointer;

      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
}
.purple-background {
  color: white;
  background-color: #6f2877;
}
.lowercase {
  text-transform: lowercase;
}
@media (min-width: $md-width) {
  .navbar-expand-md {
    flex-flow: row nowrap;
  }
}

.table-scroller {
  margin: 20px 0;
  width: 100%;

  .scroll {
    color: #ccc;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;

    @include bp-sm-up {
      display: none;
    }
  }
  .scroll-content {
    overflow: scroll;
    width: 100%;
  }

  table td {
    font-size: 14px;

    @include bp-sm-up {
      font-size: 16px;
    }
  }
}

.contact-new-layout {
	display: flex;
	gap: 100px;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 30px;
	}

	&>div {
		flex: 1;

		h5 {
			font-size: clamp(20px, 2vw, 28px);
			margin-bottom: 18px;
			color: #000;
		}

		h6 {
			font-size: clamp(18px, 2vw, 22px);
			margin-bottom: 18px;
			color: #6f2877;
		}
		a {
			color: blue;
		}

		@media (min-width: 1024px) {
			&:last-of-type p:first-of-type {
				padding-right: 20%;
			}
		}
	}
}